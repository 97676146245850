<template>
  <div
    class="basic-salary"
    v-text="salary.formatted"
    :class="bem('basic-salary', { appearance })"
  />
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'BasicSalary',
    props: {
      salary: Object,
      appearance: {
        type: String,
      }
    },
    methods: {
      bem,
    }
  }
</script>