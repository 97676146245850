$(function(){
  if($('.js_scores_settings_form').length === 0){ return false;}

  var timer;
  var delay = 500;

  $('.js_scores_settings_form').on('change', '#company_scores_enabled', function(event) {
    clearTimeout(timer);
    timer = setTimeout(function(){
      $(event.currentTarget).closest('form').submit();
    }, delay);
  });
})
