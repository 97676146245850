$(function () {
  (function(selectField) {
    if (selectField.length <= 0) return;

    const price = selectField.closest('.standart_form').find('.brand-form__price');

    selectField.on('change', function() {
      const option = $('option:selected', this);

      price.html(option.data('price'));
    }).trigger('change');
  })($('.js-company-service-option-select'));
});
