import { company_field, university_field } from "src/global-helpers.js";

$(function(){
  if(!$('.js-university-form').length) return;

  if($('.university-field').length){
    university_field($('.university-field'));
  }

  company_field($('.company-field'));
});
