$(function(){

  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-companies-form').length) return;

  import('src/libs/select2').then(() => {
    // селектор городов с автокомплитом
    $('.js-city-select').select2({
      //placeholder: "Поиск по городам",
      minimumInputLength: 2,
      ajax: {
        url: "/suggest/cities",
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            q: term, // search term
          };
        },
        results: function (data, page) {
          return { results: data.cities };
        },
        cache: true
      },
      initSelection: function(element, callback) {
        var id = $(element).val();
        if ($.trim(id) !== "") {
          $.ajax("/suggest/cities/" + id, {
            dataType: "json"
          }).done(function(data) {
            callback(data.city);
          });
        }
      },
      formatResult: format,
      formatSelection: format,
      escapeMarkup: function (m) { return m; }
    });

    // когда город выбран, необходимо сбросить универ и факультет
    $('.js-city-select').on("change", function(e) {
      $(".js-company-select").select2("data", []);
    });

    // селектор университетов с автокомплитом
    $('.js-company-select').select2({
      //placeholder: "Поиск по универам",
      minimumInputLength: 2,
      ajax: {
        url: "/suggest/companies",
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          var city = $('.js-city-select').select2("data");
          if( city  ) {
            return {
              q: term,
              city_id: city.id
            };
          } else {
            return {
              q: term
            };
          }
        },
        results: function (data, page) {
          return { results: data.companies };
        },
        cache: true
      },
      initSelection: function(element, callback) {
        var id = $(element).val();
        if ($.trim(id) !== "") {
          $.ajax("/suggest/companies/" + id, {
            dataType: "json"
          }).done(function(data) {
            callback(data.cpmpany);
          });
        }
      },
      formatResult: format,
      formatSelection: format,
      escapeMarkup: function (m) { return m; }
    });


    // вспомогательная функция которая отвечает за форматирование элемента в списке
    function format(object, container, query){
      return object.name;
    }
  });
});
