import { disableScroll, enableScroll } from 'src/utils/bodyScroll.js';

$(function() {
  $(document).on('click', '[data-mobile-menu-open]' , function() {
    $('[data-sidebar]').addClass('content-wrapper__sidebar_opened');
    disableScroll();
  });

  $(document).on('click', '[data-mobile-menu-close]' , function() {
    $('[data-sidebar]').removeClass('content-wrapper__sidebar_opened');
    enableScroll();
  });
});