import 'jquery-ui/ui/widgets/autocomplete';
import { initSkills } from "src/global-helpers.js";

$(function(){

  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-specialization-form').length) return;

  initSkills();
});

