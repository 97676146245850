import { company_field, initSkills } from "src/global-helpers.js";


$(function(){

  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-experiences-form').length) return;


  var input = $('.end_date');
  var month = input.find('select').first();

  month.on('change', toggleYear);
  toggleYear();

  function toggleYear(){
    var year = input.find('.chzn-container').last();

    if(!month.val()){
      year.hide();
    }else{
      year.show();
    }
  }

  company_field($('.company-field'));

  initSkills();
});
