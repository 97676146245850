<template>
  <transition
    name="transition-fade"
    v-bind="$attrs"
    v-on="currentListeners"
  >
    <slot />
  </transition>
</template>

<script>
  import { disableScroll, enableScroll } from 'src/utils/bodyScroll.js';

  const bodyScrollListeners = {
    enter() {
      disableScroll();
    },
    enterCancelled() {
      enableScroll();
    },
    afterLeave() {
      enableScroll();
    },
    leaveCancelled() {
      enableScroll();
    },
  };

  export default {
    name: 'TransitionFade',
    props: {
      bodyScrollLock: Boolean,
    },
    destroyed() {
      enableScroll();
    },
    computed: {
      currentListeners() {
        let result = { ...this.$listeners };

        if (this.bodyScrollLock) {
          result = { ...result, ...bodyScrollListeners };
        }

        return result;
      },
    }
  }
</script>