import { ed_platform_field, setPopupPosition, updateCoursesSortLabel } from "src/global-helpers.js";

$(function(){
  // если поисковой строки нет на странице, то код дальше можно не выполнять.
  if ($('.js-courses-search-filter-form').length === 0) return;
  
  // обработчик событий на поисковой строке.
  // проксирует значение инпута в скрытое поле в форме фильтра справа и вызывает событие change в той форме
  // в результате чего сабмитится фоорма с фильтрами и выдача изменяется.
  $('#search_courses_q').on('change keyup', function() {
    var value = $(this).val();
    var a = 1;
    $('#courses_search_q').val(value).trigger('change');
  });

});

$(function(){
  if( $('.js-courses-search-filter-form').length === 0 ) return;

  $(document).on('change', '#only_free', function(ev){
    ev.stopPropagation();
    if ($(this).prop('checked')) {
      $('.js-courses-search-filter-form').find('#price').val('');
    }

    if ($(this).id == $(ev.target).id) {
      $('.js-courses-search-filter-form').trigger('change');
    }
  });

  $(document).on('change', '#only_online', function(ev){
    ev.stopPropagation();
    if ($(this).prop('checked')) {
      $('.js-courses-search-filter-form').find('#city_id').val('');
      $('.js-courses-search-filter-form').find('input.location').val('');
    }

    if ($(this).id == $(ev.target).id) {
      $('.js-courses-search-filter-form').trigger('change');
    }
  });

  $(document).on('focus', '#price', function(ev){
    $('.js-courses-search-filter-form').find('#only_free').prop('checked', false);
  });

  $(document).on('focus', 'input.location', function(ev){
    $('.js-courses-search-filter-form').find('#only_online').prop('checked', false);
  });

  $(document).on('keyup', '#price', function(ev){
    $('.js-courses-search-filter-form').trigger('change');
  });

  $(document).on('click', '.js-courses-search-filter-form .clear_suggest', function(ev){
    $('.global_popup').remove();
    $('.js-courses-search-filter-form').trigger('change');
  });

  $(document).on('click', '.field.suggest_options .remove', function(ev){
    $(this).closest('.option').remove();
    $('.js-courses-search-filter-form').trigger('change');
  });

  var timer;
  $(document).on('change', '.js-courses-search-filter-form', function(ev){
    if ($(ev.target).hasClass('nosubmit')) { return false; }
    $('#search_courses_q').addClass('loading');
    clearTimeout(timer);
    timer = setTimeout(function(){
      setCoursesSearchUrl();
      // сабмитим форму
      $('.js-courses-search-filter-form').submit();

    }, 500);
  });

  var setCoursesSearchUrl = function () {
    var formData = $('.js-courses-search-filter-form').serializeArray();
    var params = $.map(formData, function(field, i){
      if(field.value !== '' && field.name !== 'utf8' && field.name !== 'skills_finder' && field.name !== 'specialization_name') {
        return field;
      }
    });
    var url = $.param( params );
    if(history.replaceState) {
      history.replaceState({}, '', '/courses?'+url);
    }
  };
  window.setCoursesSearchUrl = setCoursesSearchUrl;

  $('#price').on('keyup', function(){
    $(this).trigger('change');
  });

  ed_platform_field($('.ed-center-field'));
});

$(function(){
  if( $('.js-courses-search-dropdown').length === 0 ) return;

  $(document).on('change', '.js-courses-search-dropdown input', function(){
    var form = $('.js-courses-search-filter-form')
    form.find('.js-hidden-filters').remove()
    form.find('#courses_search_filter_form_sort').remove()
    $('.js-courses-search-dropdown input:checked').map(function (i, val) {
      var hiddenField = $('<input>')
        .addClass('js-hidden-filters')
        .attr('type', 'hidden')
        .attr('name', val.name)
        .val(val.value)
      form.prepend(hiddenField)
    })
    form.trigger('change')
  });

  updateCoursesSortLabel();

  $(document).on('change', '.js-courses-search-dropdown input', updateCoursesSortLabel);

  $(document).on('click', '.specialization_name', function(){
    var input = $(this);
    var field = input.closest('.field');
    var popup = field.find('.global_popup');
    popup.find('.close_popup_button').removeClass('disabled loading');
    popup.find('.close_popup_button').prop('disabled', false);
    popup.addClass('opened');
    setPopupPosition(popup);
    $('body').addClass('noscroll');
    return false;
  });
});
