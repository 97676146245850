/* eslint-disable func-names */
/**
 * В этом файле описаны все скрипты, для страницы "Поиск специалистов" /search
 */

// 1. Код для поисковой строки в верхней части списка пользователей
import { setResumesSearchUrl } from 'src/global-helpers.js';

$(function(){

  // если поисковой строки нет на странице, то код дальше можно не выполнять.
  if( $('.js-resumes-search-filter-form').length === 0 ) return;

  // обработчик событий на поисковой строке.
  // проксирует значение инпута в скрытое поле в форме фильтра справа и вызывает событие change в той форме
  // в результате чего сабмитится фоорма с фильтрами и выдача изменяется.
  $('#search_users_q').on('change keyup', function(){
    $('#resumes_search_q').val($(this).val()).trigger('change');
  });

});

// 1.1. Код для дропдауна с чекбоксами под поисковой строкой в верхней части списка пользователей
$(function(){
  // если фильтра нет на странице, то код дальше можно не выполнять.
  if( $('.js-resumes-search-dropdown').length === 0 ) return;

  // обработчик событий на поисковой строке.
  // проксирует значение инпута в скрытое поле в форме фильтра справа и вызывает событие change в той форме
  // в результате чего сабмитится фоорма с фильтрами и выдача изменяется.
  $('.js-resumes-search-dropdown input').on('change', function(){
    const form = $('.js-resumes-search-filter-form')
    form.find('.js-hidden-filters').remove()
    // eslint-disable-next-line array-callback-return
    $('.js-resumes-search-dropdown input:checked').map(function (i, val) {
      const hiddenField = $('<input>')
        .addClass('js-hidden-filters')
        .attr('type', 'hidden')
        .attr('name', val.name)
        .val(1)
      form.prepend(hiddenField)
    })
    form.trigger('change')
  });

  function showDropdownLabelForResumesSearch () {
    const checked = $('.js-resumes-search-dropdown input:checked').map(function (i, val) {
      const wrapper = $(val).closest('.checkbox_single')
      const text = wrapper.find('label').text()
      return text
    }).toArray()
    const togglerText = checked.length > 0
      ? (`Искать ${  checked.join(', ')}`)
      : 'Искать везде'
    const toggler = $('.js-resumes-search-dropdown .toggler')
    const icon = $('<span class="icon-Arrow"></span>')
    toggler.text(togglerText)
    toggler.append(icon)
  }

  // надо после загрузки страницы показать лейблы в фильтре
  showDropdownLabelForResumesSearch()

  // а так же при каждом изменении будем вычислять лейблы в фильтре
  $(document).on('change', '.js-resumes-search-dropdown input', showDropdownLabelForResumesSearch)

});

;

// 2. Код для работы блока с фильтрами в правом сайдбаре.
$(function(){

  // если блока нет на странице, то код дальше можно не выполнять.
  if( $('.js-resumes-search-filter-form').length === 0 ) return;

  // обработчик сабмита формы в правом сайдбаре
  let timer;
  $(document).on('change', '.js-resumes-search-filter-form', function(){
    $('#search_users_q').addClass('loading');
    clearTimeout(timer);
    timer = setTimeout(function(){
      setResumesSearchUrl();
      $('.js-resumes-search-filter-form').submit();

    }, 200);
  });

  // не факто что так будет ок :)
  $('#salary').on('keyup', function(){
    $(this).trigger('change');
  });

  // company_field($('.company-field'));
  // university_field($('.university-field'));

});

/* События на эмпти блоке котроый показывается чувакам у которых нет доступа к базе резюме */
$(function() {
  if ($('.no_hr_plan_empty_block').length === 0) return;

  $(document).on('click', '.no_hr_plan_empty_block .js-ga-no_company', function() {
    window.helpers.trackEvent('resumeblock', 'click', 'company_create');
  });
  $(document).on('click', '.no_hr_plan_empty_block .js-ga-no_vacancies', function() {
    window.helpers.trackEvent('resumeblock', 'click', 'vacancy_create');
  });
  $(document).on('click', '.no_hr_plan_empty_block .js-ga-sign_up', function() {
    window.helpers.trackEvent('resumeblock', 'click', 'sign_up');
  });
  $(document).on('click', '.no_hr_plan_empty_block .js-ga-sign_in', function() {
    window.helpers.trackEvent('resumeblock', 'click', 'sign_in');
  });
});

