export const clamp = (value, min = 0, max) => Math.min(max, Math.max(min, value));

export const rotateIndex = (from, by) => {
  let nullCorrection = 0
  if (from === null) {
    nullCorrection = by > 0 ? -1 : 0;
  }

  return from + nullCorrection + by
}
export const resolveCircularIndex = (index, length) => (length + index) % length;
