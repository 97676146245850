$(function(){
  if($('.js-company_users-form').length === 0){ return false;}

  var timer;
  var delay = 500;

  $('.list_search_form').on('keyup', '#term', function(event) {
    $('#term').addClass('loading');
    clearTimeout(timer);
    var q = $(this).val()
    if( 2 > q.length && q.length > 0 ) return;
    timer = setTimeout(function(){
      $(event.currentTarget).closest('form').submit();
    }, delay);
  });

  $(document).on('change', '.company_user select', function(event){
    $(event.currentTarget).closest('form').submit();
  });
    
})

