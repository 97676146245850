$(function () {
  if(!$('.nonresident_payment_form').length) return;

  function setCurrencyHint(){
    var selector = '.payment_type input[name="company_billing_account[nonresident_payment_type]"]:checked',
        hint = $(selector).data('hint');
    $('.currency_hint').text(hint);
  }

  $(document).on('change', '.payment_type input[name="company_billing_account[nonresident_payment_type]"]', function(){
    setCurrencyHint();
  });
});