<template>
  <TextInput
    v-model="model"
    v-bind="$attrs"
    v-on="filteredListeners"
    :placeholder="placeholder"
  >
    <template #icon>
      <span
        v-if="isLoading"
        class="search-input__loader"
      />
      <button
        v-else
        :disabled="!filled"
        class="search-input__icon"
        :class="bem('search-input__icon', { filled })"
        @click="clear"
      >
        <SvgIcon v-bind="iconProps"/>
      </button>
    </template>
  </TextInput>
</template>

<script>
  import { bem } from 'src/utils/bem.js';
  import { createPropModel, modelValueConfig } from 'src/utils/vue.js';

  import TextInput from 'src/components/form/TextInput/TextInput.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';

  const icons = {
    filled: {
      size: 16,
      icon: 'cross'
    },
    empty: {
      size: 24,
      icon: 'search'
    },
  };

  export default {
    name: 'SearchInput',
    components: { SvgIcon, TextInput },
    model: modelValueConfig(),
    inheritAttrs: false,
    props: {
      modelValue: String,
      placeholder: String,
      isLoading: Boolean,
    },
    methods: {
      bem,
      clear() {
        if (this.filled) {
          this.model = '';
        }
      }
    },
    computed: {
      model: createPropModel('modelValue'),
      iconProps() {
        return this.filled ? icons.filled : icons.empty;
      },
      filled() {
        return !!this.model;
      },
      filteredListeners() {
        const { 'update:modelValue':_, input, ...rest } = this.$listeners;
        return rest;
      },
    },
  };
</script>
