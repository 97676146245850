/* eslint-disable func-names,no-unused-vars,no-undef,radix */
import { initSkills, initVacancyCities } from 'src/global-helpers.js';

function getAllowedPlacementsStructure(input) {
  const result = {
    countries: [],
    regions: [],
    cities: [],
  }

  input.forEach((item) => {
    if (item.startsWith('ct_')) result.countries.push(item);

    if (item.startsWith('r_')) result.regions.push(item);

    if (item.startsWith('c_')) result.cities.push(item);
  })

  return result;
}

function getSelectedPlacementStructure (input) {
  const result = {
    country: null,
    region: null,
    city: null,
  }

  input.forEach((item) => {
    if (item.startsWith('ct_')) result.country = item;

    if (item.startsWith('r_')) result.region = item;

    if (item.startsWith('c_')) result.city = item;
  })

  return result;
}

function checkIfPlacementsMatched(allowedPlacementsArray, selectedPlacementsArray) {
  let result;

  const allowedPlacements = getAllowedPlacementsStructure(allowedPlacementsArray);

  selectedPlacementsArray.forEach((selectedItemStr) => {
    if (result === false) return;
    
    const selectedPlacement = getSelectedPlacementStructure(selectedItemStr.split(' '));

    if (
      allowedPlacements.countries.length
      && selectedPlacement.country
      && allowedPlacements.countries.includes(selectedPlacement.country)
    ) {
      result = true;
      return;
    }


    if (
      allowedPlacements.regions.length
      && selectedPlacement.region
      && allowedPlacements.regions.includes(selectedPlacement.region)
    ) {
      result = true;
      return;
    }

    if (
      allowedPlacements.cities.length
      && selectedPlacement.city
      && allowedPlacements.cities.includes(selectedPlacement.city)
    ) {
      result = true;
      return;
    }

    result = false;
  });

  return result;
}

// eslint-disable-next-line func-names
$(function(){

  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-vacancy-form').length) return;

  initSkills();
  initVacancyCities();

  if(!$('.section.placement').length) return;
  const free_presence = $('.placement_kind.free').length > 0,
        lite_presence = $('.placement_kind.lite').length > 0,
        free_quals = $('.placement_kind.free').data('qualifications') || [],
        free_specs = $('.placement_kind.free').data('specializations') || [],
        free_locations = $('.placement_kind.free').data('locations') || [],
        free_with_salary = $('.placement_kind.free').data('with_salary') || false,
        lite_quals = $('.placement_kind.lite').data('qualifications') || [],
        lite_specs = $('.placement_kind.lite').data('specializations') || [],
        lite_locations = $('.placement_kind.lite').data('locations') || [],
        lite_with_salary = $('.placement_kind.lite').data('with_salary') || false;

  function priceRequestParams() {
    const data = {};
    $('.js-vacancy-form #vacancy_salary_qualification_id, input[name="vacancy[vacancy_cities_ids][]').each(function() {
      const name = $(this).attr('name');
      if (data[name]) {
        data[name] += `, ${$(this).val()}`;
      } else {
        data[name] = $(this).val();
      }
    });

    return data;
  }

  let reloadPriceTimeoutId;
  function reloadPriceRequest() {
    togglePromoPlacements();
    const data = priceRequestParams();
    const url = $('.section.placement').data('url');
    $.ajax({
      url,
      method: 'GET',
      data,
      dataType: 'script'
    });
  }

  function reloadPrice() {
    if (reloadPriceTimeoutId) clearTimeout(reloadPriceTimeoutId);
    reloadPriceTimeoutId = setTimeout(reloadPriceRequest, 1000);
  }

  $(document).on('reloadPrice', reloadPrice)
  $(document).on('change', '#vacancy_salary_qualification_id', reloadPrice)
  $(document).on('focusout', '#vacancy_salary_from', togglePromoPlacements)
  $(document).on('focusout', '#vacancy_salary_to', togglePromoPlacements)

  const eventBus = $('.placement_kinds');

  eventBus.on('format:placement_kinds', function() {
    const visiblePlacementKindsCount = eventBus.find('.placement_kind:not(.hidden)').length;

    if (visiblePlacementKindsCount % 2 !== 0) {
      eventBus.removeClass('short').addClass('full');
    } else {
      eventBus.removeClass('full').addClass('short');
    }
  });

  // eslint-disable-next-line func-names
  const defaultNotFree = (function(tabs) {
    // active not free
    const currentActive = tabs.filter('.active').first();

    if (currentActive.length > 0) {
      return currentActive;
    }

    // not free 0 price
    // eslint-disable-next-line func-names
    const zeroPriceTab = tabs.filter(function() {
      return parseInt($(this).find('.price').text(), 10) === 0;
    });

    if (zeroPriceTab.length > 0) {
      return zeroPriceTab;
    }

    return $('.placement_kind.standard_plus');
  })($('.placement_kind:not(.free)'));

  // activate/deactivate freeTab
  (function(tab, other) {
    if (tab.length <= 0) return;

    const allowedIds = tab.data('qualifications');

    const toggleAvailability = function(enable) {
      const wasDisabled = tab.hasClass('disabled');

      if (enable) {
        tab.removeClass('disabled');

        if (wasDisabled) {
          tab.trigger('click');
        }

      } else {
        tab.addClass('disabled');

        if (!wasDisabled) {
          other.trigger('click');
        }
      }
    };

    eventBus.on('qualification:switch', function(_evt, data) {
      toggleAvailability(allowedIds.includes(data.qualificationId))
      togglePromoPlacements();
    });
  })($('.placement_kind.free'), defaultNotFree);

  const defaultNotLite = (function(tabs) {
    // active not lite
    const currentActive = tabs.filter('.active').first();

    if (currentActive.length > 0) {
      return currentActive;
    }

    // not lite 0 price
    const zeroPriceTab = tabs.filter(function() {
      return parseInt($(this).find('.price').text()) === 0;
    });

    if (zeroPriceTab.length > 0) {
      return zeroPriceTab;
    }

    return $('.placement_kind.standard_plus');
  })($('.placement_kind:not(.lite)'));

  // show/hide liteTab
  (function(tab, other) {
    if (tab.length <= 0) return;

    const allowedQualifications = tab.data('qualifications');

    eventBus.on('qualification:switch', function(_evt, data) {
      if (allowedQualifications.includes(data.qualificationId)) {
        tab.removeClass('hidden');
      } else {
        tab.addClass('hidden');
        other.trigger('click');
      }
      eventBus.trigger('format:placement_kinds')
      togglePromoPlacements();
    });
  })($('.placement_kind.lite'), defaultNotLite);

  $('.specs-selector').on('change', 'input[type=radio]', function() {
    eventBus.trigger('spec:switch', { specializationId: parseInt(this.value, 10) });
  });

  $(document).on('click', '.specs-search__item', function() {
    const specializationId = $(this).data('value');
    eventBus.trigger('spec:switch', { specializationId: parseInt(specializationId, 10) });
  });

  $('#vacancy_salary_qualification_id').on('change', function() {
    const qualificationId = this.value.length > 0 ? parseInt(this.value, 10) : null;
    eventBus.trigger('qualification:switch', { qualificationId });
  });
  eventBus.on('spec:switch', function(_evt, data) {
    togglePromoPlacements();
  });

  function togglePromoPlacements() {
    const selected_kind = $('.placement_kind.active:not(.lite):not(.free)'),
          selected_spec = parseInt($('.specs-selector__categories .basic-radio-button__input:checked').val()),
          selected_qual = parseInt($('#vacancy_salary_qualification_id').val()),
          selected_locations = $('input[name="vacancy[vacancy_cities_ids][]"]');

    let free_allowed_by_qualification = true,
        free_allowed_by_specialization = true,
        free_allowed_by_location = true,
        free_allowed_by_salary = true,
        lite_allowed_by_qualification = true,
        lite_allowed_by_specialization = true,
        lite_allowed_by_location = true,
        lite_allowed_by_salary = true;

    
    if(free_with_salary){
      free_allowed_by_salary = parseInt($('#vacancy_salary_from').val()) > 0 || parseInt($('#vacancy_salary_to').val()) > 0 
    }

    if(free_locations.length > 0 ){
      const selected_locations_ids = selected_locations.toArray().map(i => i.id).filter(Boolean)

      free_allowed_by_location = checkIfPlacementsMatched(free_locations, selected_locations_ids)
    }

    if(free_quals.length > 0){
      free_allowed_by_qualification = free_quals.includes(selected_qual);
    }
    if(free_specs.length > 0){
      free_allowed_by_specialization = free_specs.includes(selected_spec);
    }

    let enable_free = free_allowed_by_qualification && free_allowed_by_specialization && free_allowed_by_location && free_allowed_by_salary;

    if(lite_with_salary){
      lite_allowed_by_salary = parseInt($('#vacancy_salary_from').val()) > 0 || parseInt($('#vacancy_salary_to').val()) > 0 
    }
    
    if (lite_locations.length > 0 ) {
      const selected_locations_ids = selected_locations.toArray().map(i => i.id).filter(Boolean)

      lite_allowed_by_location = checkIfPlacementsMatched(lite_locations, selected_locations_ids)
    }
    
    if(lite_quals.length > 0){
      lite_allowed_by_qualification = lite_quals.includes(selected_qual);
    }
    if(lite_specs.length > 0){
      lite_allowed_by_specialization = lite_specs.includes(selected_spec);
    }

    let enable_lite = lite_allowed_by_qualification && lite_allowed_by_specialization && lite_allowed_by_location && lite_allowed_by_salary;

    let option_selected = false;

    if (enable_lite && lite_presence) {
      $('.placement_kind.lite').removeClass('hidden');

      if (!(enable_free && free_presence)) {
        $('.placement_kind.lite').trigger('click');
        option_selected = true
      }
    } else {
      $('.placement_kind.lite').addClass('hidden');
    };

    if (enable_free && free_presence) {
      $('.placement_kind.free').removeClass('disabled');
      $('.placement_kind.free').trigger('click');
      option_selected = true
    } else {
      $('.placement_kind.free').addClass('disabled');
    };

    eventBus.trigger('format:placement_kinds');

    if (!(option_selected)) {
      if (selected_kind.length > 0) {
        selected_kind.trigger('click');
      } else {
        $('.placement_kind.standard_plus').trigger('click');
      }
    }
  };

  const freePlacementTab = $('.placement_kind.free');
  const litePlacementTab = $('.placement_kind.lite');
  const buttonSections = $('.vacancy-form__footer-buttons', '.buttons');

  $(document).on('click', '.placement_kind', function () {
    if (freePlacementTab.length <= 0 && litePlacementTab.length <= 0) return; // if not available

    let key = '[data-placement="default"]';
    if (freePlacementTab.hasClass('active')) {
      key = '[data-placement="free"]';
    } 

    if (litePlacementTab.hasClass('active')) {
      key = '[data-placement="lite"]';
    }

    buttonSections.addClass('hidden').filter(key).removeClass('hidden');
  });

  // TODO: change to `one`
  eventBus.on('form:changed', function() {
    $('.only_buttons').find('[data-changed-title]').each(function(_idx, button) {
      const $button = $(button);
      $button.val($button.data('changed-title'));
    });
  });

  $('.js-vacancy-form').on('input change', function(evt) {
    if (evt.target.classList.contains('text-input__input')) return;

    eventBus.trigger('form:changed');
  });

  document.addEventListener('reloadSalary', function() {
    eventBus.trigger('form:changed');
  }, false)

  document.addEventListener('reloadPrice', function() {
    eventBus.trigger('form:changed');
  }, false)

  // $(document).on('change', '.division_checkbox',  function(){
  //   var input = $(this);
  //   if (input.hasClass('division_analytics') && input.prop('checked')) {
  //     $('.placement_kind.lite').removeClass('hidden');
  //     $('.placement_kinds').removeClass('short').addClass('full');
  //   } else {
  //     $('.placement_kind.lite').addClass('hidden');
  //     $('.placement_kinds').removeClass('full').addClass('short');
  //     $('.placement_kind.standard_plus').click();
  //   }
  // })
});
