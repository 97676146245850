$(document).on('click', '[data-vacancy-favorite-for]', function favoriteHandler (event) {
  event.preventDefault();

  const button = $(this);
  const id = button.data('vacancyFavoriteFor');
  const isActive = button.data('active');
  const activeTitle = button.data('active-title');
  const inActiveTitle = button.data('inactive-title');

  button.prop('disabled', true);

  $.ajax({
    url: `/api/frontend/vacancies/${id}/favorite`,
    method: isActive ? 'DELETE' : 'POST',
    success(data) {
      if (data && data.redirect) {
        window.location = data.redirect;
      } else {
        button.data('active', !isActive);
        button.attr('data-active', !isActive);
        button.attr('title', isActive ? inActiveTitle : activeTitle)
      }
    },
    error() {
      window.helpers.notify('Действие недоступно', 'error');
    },
    complete() {
      button.prop('disabled', false);
    }
  });
})
