<template>
  <section
    class="vacancy-banner"
    :class="bem('vacancy-banner', { type: bannerType, single })"
    :style="{'--vacancy-banner-bg': color}"
  >
    <a
      @click="logEvent"
      target="_blank"
      :href="vacancy.href"
      class="vacancy-banner__link"
      v-text="vacancy.title"
    />
    <div
      class="vacancy-banner__image mq-not-mobile"
      :class="bem('vacancy-banner__image', { type: bannerType })"
    >
      <div class="vacancy-banner__hot-label">Горячая вакансия</div>
      <img
        :src="vacancy.company.logo.src"
        :alt="`Логотип компании «${vacancy.company.title}»`"
        class="vacancy-banner__logo"
      >
    </div>
    <div class="vacancy-banner__content">

      <div class="vacancy-banner__company-name">
        <a @click="logEvent" :href="vacancy.href" class="vacancy-banner__icon-link mq-mobile-only">
          <img
            :src="vacancy.company.logo.src"
            :alt="`Логотип компании «${vacancy.company.title}»`"
            class="vacancy-banner__icon"
          >
        </a>

        <div class="vacancy-banner__company">
          <div class="vacancy-banner__company-title">
            <BasicLink
              :title="vacancy.company.title"
              :href="vacancy.company.href"
              appearance="white"
            />
          </div>
          <CompanyIcons
            :company="vacancy.company"
            accredited-color="white"
          />
          <div
            v-if="vacancy.company.rating"
            class="vacancy-banner__company-rating"
          >
            <CompanyRating color="white" :rating="vacancy.company.rating" />
          </div>
        </div>
      </div>

      <div class="vacancy-banner__title" v-text="vacancy.title" />

      <div class="vacancy-banner__meta">
        <span v-for="metaItem in meta" :key="metaItem" class="vacancy-banner__meta-item">
          {{ metaItem }}
        </span>
      </div>
      <div
        v-if="vacancy.salary"
        class="vacancy-banner__salary"
      >
        <BasicSalary appearance="banner" :salary="vacancy.salary" />
      </div>
      <div class="vacancy-banner__skills">
        <span v-for="skill in skills" :key="skill" class="vacancy-banner__skill-item">
          {{ skill }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>

  import { bem } from 'src/utils/bem.js';

  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import BasicSalary from 'src/components/BasicSalary/BasicSalary.vue';
  import CompanyIcons from 'src/components/companies/CompanyIcons/CompanyIcons.vue';
  import CompanyRating from 'src/components/resume/CompanyRating/CompanyRating.vue';

  const COLORS = {
    pink: '#BA6DC9',
    violet: '#9773DE',
    blue: '#6073D1',
    marine: '#428FB8',
    green: '#3AA191',
  };

  export default {
    name: 'VacancyBanner',
    components: { CompanyIcons, CompanyRating, BasicLink, BasicSalary },
    inject: ['mediaQueries'],
    props: {
      vacancy: {
        type: Object,
        required: true,
      },
      single: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      bem,
      logEvent() {
        window.helpers.trackEvent('vacancy_block', 'vacancy', this.vacancy.id, true);
      },
    },

    computed: {
      meta() {
        const { location, locations, employment, remoteWork } = this.vacancy;

        const res = [];

        if (locations) {
          res.push(...locations);
        } else if (location) {
          res.push(location.title);
        }

        if (employment) {
          res.push(employment);
        }

        if (remoteWork) {
          res.push(remoteWork);
        }

        return Array.from(new Set(res));
      },
      // eslint-disable-next-line complexity
      skills() {
        const { divisions, salaryQualification, skills } = this.vacancy;

        const res = [];

        if (divisions && divisions.length) {
          res.push(...divisions.map(({ title }) => title))
        }

        if (salaryQualification) {
          res.push(salaryQualification.title)
        }

        if (skills && skills.length) {
          res.push(...skills.map(({ title }) => title))
        }

        return Array.from(new Set(res));
      },
      bannerType() {
        return this.vacancy.bannerType || 'pink'
      },
      color() {
        return COLORS[this.bannerType];
      },
    },
  };
</script>
