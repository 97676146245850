/**
 * В этом файле описаны все скрипты, для страницы "Поиск компаний" /companies
 */

// 1. Код для поисковой строки в верхней части списка компаний

import Vue from 'vue';

import { updateCompaniesListUrl, updateCompaniesSortLabel } from 'src/global-helpers.js';

import SearchInput from 'src/components/form/SearchInput/SearchInput.vue';

$(function(){

  // если поисковой строки нет на странице, то код дальше можно не выполнять.
  if( $('.js-companies-search-filter-form').length === 0 ) return;
  const $input = $('#search_companies_q')
  const value = $input.val();
  const placeholder = $input.attr('placeholder');
  const a = 1;

  const searchInput = new Vue({
    name: 'SearchInputContainer',
    data() {
      return {
        value,
        isLoading: false,
      };
    },
    methods: {
      updateValue(newValue) {
        this.value = newValue;
        // обработчик событий на поисковой строке.
        // проксирует значение инпута в скрытое поле в форме фильтра справа и вызывает событие change в той форме
        // в результате чего сабмитится фоорма с фильтрами и выдача изменяется.
        $('#companies_search_q').val(newValue).trigger('change');
      },
      setLoading(isLoading) {
        this.isLoading = isLoading;
      }
    },
    render(h) {
      return h(SearchInput, {
        props: {
          modelValue: this.value,
          isLoading: this.isLoading,
          placeholder
        },
        on: {
          'update:modelValue': this.updateValue,
        },
      })
    },
  });

  searchInput.$mount($input.parent().get(0));

  // 2. Код для работы блока с фильтрами в правом сайдбаре.
  $('body').on('click', '.companies-item .average', function(){
    const el = $(this).find('.toggle_details');
    if (el.hasClass('collapse')) {
      el.removeClass('collapse');
    } else {
      el.addClass('collapse');
    }
    $(this).closest('.companies-item').find('.score_details').toggleClass('hidden');
  });

  // обработчик сабмита формы в правом сайдбаре
  let timer;
  $(document).on('change', '.js-companies-search-filter-form', function(){
    clearTimeout(timer);
    timer = setTimeout(function(){
      updateCompaniesListUrl();
      searchInput.setLoading(true);
      $('.js-companies-search-filter-form').submit();

    }, 200);
  });
  window.setSearchInputLoading = searchInput.setLoading;
});

$(function(){
  // если фильтра нет на странице, то код дальше можно не выполнять.
  if( $('.js-companies-search-dropdown').length === 0 ) return;

  // обработчик событий на поисковой строке.
  // проксирует значение инпута в скрытое поле в форме фильтра справа и вызывает событие change в той форме
  // в результате чего сабмитится фоорма с фильтрами и выдача изменяется.
  $(document).on('change', '.js-companies-search-dropdown input', function(){
    const form = $('.js-companies-search-filter-form')
    form.find('.js-hidden-filters').remove();
    form.find('#companies_search_filter_form_sort').remove();
    $('.js-companies-search-dropdown input:checked').each(function (i, val) {
      const hiddenField = $('<input>')
        .addClass('js-hidden-filters')
        .attr('type', 'hidden')
        .attr('name', val.name)
        .val(val.value)
      form.prepend(hiddenField)
    })
    form.trigger('change')
  });

  // надо после загрузки страницы показать лейблы в фильтре
  updateCompaniesSortLabel();

  // а так же при каждом изменении будем вычислять лейблы в фильтре
  $(document).on('change', '.js-companies-search-dropdown input', updateCompaniesSortLabel)

});
