<template>
  <span
    class="basic-text"
    :class="bem('basic-text', { color, size, align, weight })"
  ><slot /></span>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'BasicText',
    props: {
      color: String,
      size: String,
      align: String,
      weight: String,
    },
    methods: {
      bem,
    }
  }
</script>