import { clear_form_errors } from "src/global-helpers.js";
import { getFormattedNumber } from 'src/utils/translation.js';

$(function () {
  if(!$('.js-accounts-form').length) return;

  import('src/libs/suggestions').then(() => {
    var caFieldsDataStorage = {},
      caUsableFields = ['#company_billing_account_inn', '#company_billing_account_kpp',
        '#company_billing_account_legal_name', '#company_billing_account_short_name',
        '#company_billing_account_legal_address', '#company_billing_account_company_name',
        '#company_billing_account_company_address', '#company_billing_account_person_name'];

    function showAccountFields () {
      clear_form_errors();
      // Прячем поля для бранчей
      $('.field.branch').hide();
      $('#billing_account_fields').removeClass('branch').removeClass('fo').removeClass('fp');

      var selected = $('#account_kind input[type="radio"]:checked'),
        kind = selected.val();

      if (kind != caFieldsDataStorage.currentKind && caFieldsDataStorage.currentKind) {
        caStoreCurrentData(caFieldsDataStorage.currentKind);
      }

      if (selected.data('company')) {
        $('#agreement .rules').attr('href', $('#agreement').data('rules'));
        $('#billing_account_fields').removeClass('hidden');
        $('#nonresident_account_fields').addClass('hidden');
        $('#billing_account_fields input, #billing_account_fields select, #billing_account_fields textarea').prop('disabled', false);
        $('#nonresident_account_fields input, #nonresident_account_fields select, #nonresident_account_fields textarea').prop('disabled', true);
        // Выбрано юр. лицо, филиал или ИП
        $('#billing_account_fields').attr('class', 'company');
        var params = {type: "LEGAL", branch_type: "MAIN", status: ["ACTIVE"]};

        if (selected.data('ie') == true) {
          // Выбрано ИП
          params.type = 'INDIVIDUAL';
        }
        if (selected.data('branch') == true) {
          // Филиал
          $('#billing_account_fields').addClass('branch');
        }
        initDadataForCompanies(params);
        $('#payment_type_select').find(".radio_single.company:first input:radio").click();
        $('.button.form-save').val('Создать новый лицевой счёт и отправить заявку на пополнение');
      } else if (selected.data('foreign')) {
        $('#agreement .rules').attr('href', $('#agreement').data('rules'));
        $('#billing_account_fields').removeClass('hidden');
        $('#nonresident_account_fields').addClass('hidden');
        $('#billing_account_fields input, #billing_account_fields select, #billing_account_fields textarea').prop('disabled', false);
        $('#nonresident_account_fields input, #nonresident_account_fields select, #nonresident_account_fields textarea').prop('disabled', true);
        $('#billing_account_fields').attr('class', 'foreign');
        $('#payment_type_select').find(".radio_single.foreign:first input:radio").click();
        $('.button.form-save').val('Создать новый лицевой счёт и отправить заявку на пополнение');
        if (selected.data('fo')) {
          $('#billing_account_fields').addClass('fo');
          $('#company_billing_account_currency_cd_2').click();
        }
      } else if (selected.data('nonresident')) {
        $('#agreement .rules').attr('href', $('#agreement').data('rules-hbp'));
        $('#billing_account_fields').addClass('hidden');
        $('#nonresident_account_fields').removeClass('hidden');
        $('#billing_account_fields input, #billing_account_fields select, #billing_account_fields textarea').prop('disabled', true);
        $('#nonresident_account_fields input, #nonresident_account_fields select, #nonresident_account_fields textarea').prop('disabled', false);
        if ($('#company_billing_account_currency_cd_2').length > 0) {
          $('#company_billing_account_currency_cd_2').click();
        } else {
          $('.radio_list.currency').find(".radio_single:first input:radio").click();
        }
        $('#nonresident_payment_type_select').find(".radio_single.foreign:first input:radio").click();
        setCurrencyHint();
      } else {
        $('#agreement .rules').attr('href', $('#agreement').data('rules'));
        $('#billing_account_fields').removeClass('hidden');
        $('#nonresident_account_fields').addClass('hidden');
        $('#billing_account_fields input, #billing_account_fields select, #billing_account_fields textarea').prop('disabled', false);
        $('#nonresident_account_fields input, #nonresident_account_fields select, #nonresident_account_fields textarea').prop('disabled', true);
        $('#billing_account_fields').attr('class', 'personal');
        if (selected.data('fp') == true) {
          $('#billing_account_fields').addClass('fp');
        }
        initDadataForPersonal();
        $('#payment_type_select').find(".radio_single.personal:first input:radio").click();
        $('.button.form-save').val('Создать и пополнить новый лицевой счёт');
      }

      caClearCurrentData();

      if (kind != caFieldsDataStorage.currentKind && caFieldsDataStorage.currentKind) {
        caRestoreCurrentData(kind);
        if (selected.data('company')) {
          var suggestionsPlugin = $("#company_billing_account_inn").suggestions()
          suggestionsPlugin.fixData();
        } else if (!selected.data('foreign')) {
          var suggestionsPlugin = $("#company_billing_account_person_name").suggestions()
          suggestionsPlugin.fixData();
        }
      }

      caFieldsDataStorage.currentKind = kind;

      $("#company_billing_account_inn").closest('.field').find('.description').html('');
      $("#company_billing_account_person_name").val('');

      return false
    }

    function showNonResidentFields () {
      var selected = $('#nonresident_type input[type="radio"]:checked'),
        kind = selected.val();
      if (kind == 'company') {
        $('#nonresident_account_fields .company:not(.personal)').removeClass('hidden');
      } else {
        $('#nonresident_account_fields .company:not(.personal)').addClass('hidden');
      }
    }

    $(document).on('change', '#account_kind input[type="radio"]', showAccountFields)
    showAccountFields();

    $(document).on('change', '#nonresident_type input[type="radio"]', showNonResidentFields)

    function initDadataForCompanies(params){
      $("#company_billing_account_inn").suggestions({
        serviceUrl: "https://dadata.ru/api/v2",
        token: "434fc09ce935277b90dcb83d00dcfa100a6d0ca8",
        type: "PARTY",
        params: params,
        count: 5,
        onSelect: function(suggestion) {
          var name = suggestion.data.name.full_with_opf;
          var address = suggestion.data.address.value;
          $("#company_billing_account_inn").val(suggestion.data.inn);
          $("#company_billing_account_kpp").val(suggestion.data.kpp);
          $("#company_billing_account_company_name").val(name);
          $("#company_billing_account_company_address").val(address);
          var description = name;
          description += '<br/>ИНН: ' + suggestion.data.inn;
          if (suggestion.data.kpp) {
            description += '<br/>КПП: ' + suggestion.data.kpp;
          }
          description += '<br/>Юридический адрес компании: ' + address;
          $("#company_billing_account_inn").closest('.field').find('.description').html(description);

          if ($('#billing_account_fields').hasClass('branch')) {
            $('.field.branch').show();
          }
        }
      });
      var suggestionsPlugin = $("#company_billing_account_inn").suggestions()
      suggestionsPlugin.fixData();
    }

    function initDadataForPersonal(){
      $("#company_billing_account_person_name").suggestions({
        serviceUrl: "https://dadata.ru/api/v2",
        token: "434fc09ce935277b90dcb83d00dcfa100a6d0ca8",
        type: "NAME",
        triggerSelectOnSpace: false,
        hint: "",
        noCache: true,
      });
    }

    function caStoreCurrentData(kind){
      caFieldsDataStorage[kind] = {};
      caUsableFields.forEach(function(element) {
        caFieldsDataStorage[kind][element] = $(element).val();
      });
    }

    function caRestoreCurrentData(kind){
      if (!caFieldsDataStorage[kind]) { return false };
      caUsableFields.forEach(function(element) {
        $(element).val(caFieldsDataStorage[kind][element]);
      });
    }

    function caClearCurrentData(kind){
      caUsableFields.forEach(function(element) {
        $(element).val('');
      });
    }

    function setPaymentType(){
      var eaeu_member = $('.country').hasClass('eaeu'),
          rur_selected = $('#company_billing_account_currency_cd_0').prop('checked'),
          is_personal = $('#company_billing_account_client_type_personal').prop('checked'),
          yookassa_enabled = $('.country').hasClass('yookassa_enabled'),
          yookassa_disabled = $('.country').hasClass('yookassa_disabled');
      if (eaeu_member && rur_selected) {
        if (is_personal) {
          $('#nonresident_payment_type_select .rc').addClass('hidden');
          $('#nonresident_payment_type_select .ac').removeClass('hidden');
          $('#company_billing_account_nonresident_payment_type_ac').click();
        } else {
          $('#nonresident_payment_type_select .ac').addClass('hidden');
          $('#nonresident_payment_type_select .rc').removeClass('hidden');
          $('#company_billing_account_nonresident_payment_type_rc').click();
        }
        $('#agreement .rules').attr('href', $('#agreement').data('rules'));
      } else {
        $('#nonresident_payment_type_select .ac').removeClass('hidden');
        $('#nonresident_payment_type_select .rc').removeClass('hidden');
        $('#company_billing_account_nonresident_payment_type_ac').click();
        $('#agreement .rules').attr('href', $('#agreement').data('rules-hbp'));
      }
      if (rur_selected && is_personal) {
        if (yookassa_enabled) {
          $('.yookassa_available').removeClass('hidden');
        } else {
          $('.yookassa_available').addClass('hidden');
        }
        if (yookassa_disabled) {
          $('.yookassa_notavailable').removeClass('hidden');
        } else {
          $('.yookassa_notavailable').addClass('hidden');
        }
      } else {
        $('.yookassa_available').addClass('hidden');
        $('.yookassa_notavailable').addClass('hidden');
      }
    }

    function setCurrencyHint(){
      var selector = '.currency input[name="company_billing_account[currency_cd]"]:checked',
          hint = $(selector).data('hint'),
          rc_hint = $(selector).data('rc-hint'),
          title = $(selector).data('title'),
          new_hint = hint;
      if ($('#company_billing_account_nonresident_payment_type_ac').prop('checked')) {
        new_hint = rc_hint;
      }
      $('#nonresident_account_fields .currency_hint').text(new_hint);
      $('#nonresident_account_fields .currency_title').text(title);
    }

    $(document).on('change', '.currency input[name="company_billing_account[currency_cd]"]', function(){
      setPaymentType();
      setCurrencyHint();
    });

    $(document).on('change', '.payment_type input[name="company_billing_account[nonresident_payment_type]"]', function(){
      setCurrencyHint();
    });

    $(document).on('change', '.client_type input[name="company_billing_account[client_type]"]', function(){
      setPaymentType();
    });

    $(document).on('change', '.country', function(){
      var eaeu_member = $('.country').hasClass('eaeu'),
          eu_member = $('.country').hasClass('eu');
      if (eaeu_member) {
        $('#nonresident_account_fields .currency .usd').removeClass('hidden');
        $('#nonresident_account_fields .currency .rur').removeClass('hidden');
        $('#company_billing_account_currency_cd_2').click();
      } else if (eu_member) {
        $('#nonresident_account_fields .currency .rur').addClass('hidden');
        $('#nonresident_account_fields .currency .usd').addClass('hidden');
        $('#company_billing_account_currency_cd_1').click();
      } else {
        $('#nonresident_account_fields .currency .usd').removeClass('hidden');
        $('#nonresident_account_fields .currency .rur').addClass('hidden');
        $('#company_billing_account_currency_cd_2').click();
      }
      setPaymentType();
      setCurrencyHint();
    });
  });
});

const debouncer = function(fn, delay) {
  var timer = null;

  return function() {
    var context = this;
    var args = arguments;

    clearTimeout(timer);

    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);

    return timer;
  };
};

$(function () {
  if(!$('#company_billing_account_code').length) return;

  const getCodeInfo = function(code) {
    $.ajax({
      url: '/suggest/codes',
      data: {code: code},
      method: 'GET',
      dataType: 'json',
      success: function(data){
        $('#company_billing_account_code').closest('.promo_field').find('.count').text(data['credits'] + ' руб.');
      }
    });
  }

  $('#company_billing_account_code').on('change keyup', function() {
    var code = $(this).val();
    if(code.length < 25) {
      $('#company_billing_account_code').closest('.promo_field').find('.count').text('0 руб.');
      return false
    }
    debouncer(function (e) { getCodeInfo(code) }, 300)();
  });

  $('#company_billing_account_code').on('paste', function(e) {
    var code = (e.originalEvent || e).clipboardData.getData('text/plain');
    if(code.length < 15) {
      $('#company_billing_account_code').closest('.promo_field').find('.count').text('0 руб.');
      return false;
    }
    debouncer(function (e) { getCodeInfo(code) }, 300)();
  });
});

$(function () {
  if(!$('#company_billing_account_refill').length) return;

  $(document).on('keyup change', '.js-credits-count', function(){
    var count = parseInt($(this).val()) || 0;

    var target = $(this).closest('.promo_field').find('.value');
    if (count >= 10000) {
      target.text('+ ' + getFormattedNumber(count) + ' руб.')
    } else {
      target.text('0 руб.')
    }
  });
});

$(function () {
  if(!$('#account_transfer').length) return;

  $(document).on('change', '.standart_form input[type="checkbox"]', function(){
    if ($(this).is(":checked")) {
      $('#submit').removeAttr('disabled');
    } else {
      $('#submit').attr('disabled', 'disabled');
    }
  });
});