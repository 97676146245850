$(function(){
  if(!$('.js-salaries-reports').length) return;
  // $('.graph_item.specialization').toggleClass('hidden');

  $('.js-salaries-reports-form').on('change', function(){
    $(this).submit();
  })

  $('.js-salaries-reports .show_specializations').on('click', function(){
    $(this).closest('.graph_item.group').find('.specialization').toggleClass('hidden');
    $(this).remove();
  })
});
