$(function () {
  if(!$('.js-company_cp_billing_yandex_moneys_path-form').length) return;

  // Меняем URL формы в зависимости от выбраннной платежной системы
  $(document).on('change', '#company_payment_transactions_yandex_money_payment_type input[type="radio"]', function () {
    var radio = $(this)
    var form = radio.closest('form')
    var url = radio.attr('data-url')
    form.attr('action', url)
  });

  // скрыть/показать различные поля в форме реквизитов компании
  function showCompanyFields () {
    var selected = $('#company_payment_request_kind input[type="radio"]:checked').val()
    $('#company_about_fields').attr('class', selected)
    return false
  }

  $(document).on('change', '#company_payment_request_kind input[type="radio"]', showCompanyFields)
  $(document).on('ready', showCompanyFields)

  // расчет бонусов
  $(document).on('keyup change', '.js-credits-count', function(){
    var bonus_count = $('#bonus_count');
    var bonuses = $(this).data('bonuses'); // {"15000":2500,"30000":10000,"50000":20000}

    // введенная сумма
    var count = parseInt($(this).val()) || 0;
    var bonus = 0;

    // расчет бонусов, на основе введенной суммы.
    $.each(bonuses, function(k,v){
      var min = parseInt(k);
      if( count >= min){
        bonus = v;
      }
    });

    if( bonus > 0 ){
      bonus_count.text('+ '+bonus);
    }else{
      bonus_count.text(bonus);
    }

    $('#total_price').text(count);
  });

  // когда курсор покидает поле ввода, мы проверяем введенную сумму
  // и если она меньше минимальной, то вставляем в поле минимульную сумму
  $(document).on('blur', '.js-credits-count', function(){
    var min_count = parseInt($(this).data('min_count')) || 0;

    var count = parseInt($(this).val()) || 0;
    if(count < min_count) count = min_count;
    $(this).val(count).trigger('change');
  });

  // при первой загрузке странице - пересчитаем бонусы
  $('.js-credits-count').trigger('change');

});
