let width;

function getScrollbarWidth() {
  if (typeof width === 'undefined') {
    width = window.innerWidth - document.documentElement.clientWidth;
  }
  return width;
}

let lastScroll;

export const disableScroll = () => {
  const currentScroll = lastScroll = document.documentElement.scrollTop;
  document.body.style.cssText = `
    position: fixed;
    top: -${currentScroll}px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    padding-right: ${getScrollbarWidth()}px;
  `;
};

export const enableScroll = () => {
  document.body.style.cssText = '';

  if (typeof lastScroll !== 'undefined') {
    window.scrollTo(0, lastScroll);
    lastScroll = undefined;
  }
};

export const bodyScrollDirective = {
  bind(el) {
    disableScroll();
  },
  unbind(el) {
    enableScroll();
  }
};
