import 'src/legacy-globals.js';
import 'src/parts/divisions-selector.js';
import 'src/parts/placement_kinds.js';
import 'src/parts/multiselect.js';
import 'src/pages/profile/educations.js';
import 'src/pages/profile/experiences.js';
import 'src/pages/profile/interest.js';
import 'src/pages/profile/contacts.js';
import 'src/pages/profile/companies.js';
import 'src/pages/profile/specialization.js';
import 'src/pages/profile/social_ratings.js';
import 'src/pages/onboard/sticky_header.js';
import 'src/pages/onboard/hh_profile_load.js';
import 'src/pages/resumes/list.js';
import 'src/pages/opinions/index.js';
import 'src/pages/vacancies/favorite.js';
import 'src/parts/show.js';
import 'src/pages/conversations/index.js';
import 'src/pages/conversations/templates.js';
import 'src/pages/companies/show.js';
import 'src/pages/companies/list.js';
import 'src/pages/companies/new.js';
import 'src/pages/companies/photos.js';
import 'src/pages/companies/cp/company_users.js';
import 'src/pages/companies/cp/photos.js';
import 'src/pages/companies/cp/carts.js';
import 'src/pages/companies/cp/payments.js';
import 'src/pages/companies/cp/transactions.js';
import 'src/pages/companies/cp/scores.js';
import 'src/pages/companies/cp/accounts.js';
import 'src/pages/companies/cp/vacancies/form.js';
import 'src/pages/companies/cp/vacancies/list.js';
import 'src/pages/companies/cp/documents.js';
import 'src/pages/companies/cp/arsenal.js';
import 'src/pages/companies/cp/nonresident.js';
import 'src/pages/companies/cp/brands.js';
import 'src/pages/salaries/form.js';
import 'src/pages/salaries/index.js';
import 'src/pages/salaries/reports.js';
import 'src/pages/courses/list.js';
import 'src/pages/courses/form.js';
import 'src/pages/universities/form.js';
import 'src/pages/universities/list.js';
import 'src/pages/universities/courses.js';
import 'src/pages/user_graphs/index.js';
import 'src/pages/user_graphs/form.js';
import 'src/pages/company_ratings/index.js';
import 'src/pages/company_ratings/form.js';
import 'src/pages/users/show.js';

import 'src/libs/chosen';

import 'styles/views/layouts/onboarding.css.sass';
import 'styles/parts/paginator.css.sass';
import 'styles/parts/forms.css.sass';
import 'styles/parts/sections.css.sass';
import 'styles/parts/list_search_form.css.sass';
import 'styles/parts/dropdown.css.sass';
import 'styles/parts/ui-autocomplete.css.sass';
import 'styles/parts/global_popup.css.sass';
import 'styles/parts/social_buttons.css.sass';
import 'styles/parts/user_filters.css.sass';
import 'styles/parts/company_ratings.css.sass';
import 'styles/parts/vacancy_services.css.sass';
import 'styles/parts/no_content.sass';
import 'styles/parts/payment_result_page.sass';
import 'styles/views/info/about.css.sass';
import 'styles/views/info/help.css.sass';
import 'styles/views/info/service.css.sass';
import 'styles/views/info/partners.css.sass';
import 'styles/views/info/api.css.sass';
import 'styles/views/info/price.sass';
import 'styles/views/profile/shared/sidebar.css.sass';
import 'styles/views/profile/educations.css.sass';
import 'styles/views/profile/experiences.css.sass';
import 'styles/views/profile/links.css.sass';
import 'styles/views/profile/misc.css.sass';
import 'styles/views/profile/social_ratings.css.sass';
import 'styles/views/profile/applications.css.sass';
import 'styles/views/profile/company_ratings.css.sass';
import 'styles/views/preferences/socials.css.sass';
import 'styles/views/resumes/list.css.sass';
import 'styles/views/responses/list.css.sass';
import 'styles/views/users/sidebar.css.sass';
import 'styles/views/users/show.css.sass';
import 'styles/views/users/friends.css.sass';
import 'styles/views/users/opinions.css.sass';
import 'styles/views/users/users-about-items.css.sass';
import 'styles/views/conversations/index.css.sass';
import 'styles/views/conversations/sidebar.css.sass';
import 'styles/views/conversations/templates.sass';
import 'styles/views/vacancies/show.css.sass';
import 'styles/views/vacancies/landing_links.css.sass';
import 'styles/views/vacancies/job_show_header.sass';
import 'styles/views/vacancies/responses.sass';
import 'styles/views/vacancies/response.sass';
import 'styles/views/vacancies/responses_comments.css.sass';
import 'styles/views/vacancies/sidebar/add_vacancy.css.sass';
import 'styles/views/vacancies/filter_tags.sass';
import 'styles/views/vacancies/placement_kinds.sass';
import 'styles/views/vacancies/vacancy_poll.sass';
import 'styles/views/vacancies/vacancy_suitable_users_and_guests.sass';
import 'styles/views/vacancies/response_analytics.sass';
import 'styles/views/vacancy_subscriptions/index.css.sass';
import 'styles/views/vacancy_subscriptions/form.css.sass';
import 'styles/views/catalog/index.css.sass';
import 'styles/views/companies/index.css.sass';
import 'styles/views/companies/new.css.sass';
import 'styles/views/companies/show.css.sass';
import 'styles/views/companies/photos.css.sass';
import 'styles/views/companies/cp/sidebar.css.sass';
import 'styles/views/companies/cp/vacancies.css.sass';
import 'styles/views/companies/cp/carts.css.sass';
import 'styles/views/companies/cp/memberships.css.sass';
import 'styles/views/companies/cp/payments.css.sass';
import 'styles/views/companies/cp/resumes_access.css.sass';
import 'styles/views/companies/cp/transactions.css.sass';
import 'styles/views/companies/cp/arsenal.css.sass';
import 'styles/views/companies/shared/sidebar.css.sass';
import 'styles/views/companies/shared/vacancies_list.css.sass';
import 'styles/views/salaries_reports/index.css.sass';
import 'styles/views/shared/sidebar/add_company.css.sass';
import 'styles/views/shared/sidebar/banner.css.sass';
import 'styles/views/shared/sidebar/search_filter.css.sass';
import 'styles/views/shared/sidebar/freelansim_tasks.css.sass';
import 'styles/views/shared/sidebar/inner_project_banner.css.sass';
import 'styles/views/shared/sidebar/timepad.css.sass';
import 'styles/views/shared/sidebar/salaries.css.sass';
import 'styles/views/shared/top_menu.css.sass';
import 'styles/views/shared/paginator.css.sass';
import 'styles/views/shared/hr_only_popup.css.sass';
import 'styles/views/shared/share_buttons.sass';
import 'styles/views/salaries/index.css.sass';
import 'styles/views/salaries/reports.css.sass';
import 'styles/views/company_ratings/ratings.css.sass';
import 'styles/views/courses/course.sass';
import 'styles/views/courses/course_header.sass';
import 'styles/views/blog/list.css.sass';
