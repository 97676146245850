import { updateUniversitiesListUrl } from "src/global-helpers.js";

$(function(){
  if( $('.js-universities-search-filter-form').length === 0 ) return;
  
  $('#search_universities_q').on('change keyup', function(){
    $('#universities_search_q').val($(this).val()).trigger('change');
  });
});

$(function(){
  if( $('.js-universities-search-filter-form').length === 0 ) return;

  var timer;
  $(document).on('change', '.js-universities-search-filter-form', function(){
    $('#search_universities_q').addClass('loading');
    clearTimeout(timer);
    timer = setTimeout(function(){
      updateUniversitiesListUrl();
      $('.js-universities-search-filter-form').submit();

    }, 200);
  });
});
