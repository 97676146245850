<template>
  <TransitionFade>
    <div v-if="show" class="vacancy-banner-slider swiper">
      <div class="vacancy-banner-slider__controls">
        <template v-if="vacancies.length > 1">
          <button
            class="vacancy-banner-slider__button vacancy-banner-slider__button--next"
            ref="next"
            @click="logEvent('right')"
          >
            <span>Следующая вакансия</span>
            <SvgIcon size="48" icon="arrow-big" />
          </button>
          <button
            class="vacancy-banner-slider__button vacancy-banner-slider__button--prev"
            ref="prev"
            @click="logEvent('left')"
          >
            <span>Предыдущая вакансия</span>
            <SvgIcon size="48" icon="arrow-big" />
          </button>
        </template>

        <button
          type="button"
          class="vacancy-banner-slider__close"
          @click="onClose"
        >
          <SvgIcon icon="cross" size="16" color="white" />
          <span>Спрятать баннер</span>
        </button>
      </div>
      <div class="swiper-wrapper">
        <div
          v-for="vacancy in vacancies"
          :key="vacancy.id"
          class="vacancy-banner-slider__slide swiper-slide"
        >
          <VacancyBanner :single="vacancies.length === 1" :vacancy="vacancy" />
        </div>
      </div>
      <div v-if="vacancies.length > 1" ref="pagination" class="swiper-pagination"></div>
    </div>
  </TransitionFade>
</template>

<script>
  import 'swiper/scss';
  import Cookie from 'js-cookie';
  import Swiper from 'swiper';
  import { Navigation, Pagination } from 'swiper/modules';

  import { axiosInstance } from 'src/services/api.js';

  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
  import TransitionFade from 'src/components/transitions/TransitionFade/TransitionFade.vue';
  import VacancyBanner from 'src/components/vacancies/VacancyBanner/VacancyBanner.vue';

  const TRACKING_TIMEOUT = 2500;
  const GUEST_COOKIE_NAME = 'gcb';

  export default {
    name: 'VacancyBannerSlider',
    components: { TransitionFade, SvgIcon, VacancyBanner },
    props: {
      vacancies: {
        type: Array,
      },
      isGuest: {
        type:Boolean,
        required: true,
      }
    },
    data() {
      return {
        swiperInstance: null,
        show: true,
        currentIndex: 0,
        trackingTimeoutId: null,
      };
    },
    watch: {
      currentVacancy() {
        this.handleTrackSlideView()
      },
    },
    mounted() {
      this.initSwiper();
    },
    methods: {
      logEvent(action) {
        window.helpers.trackEvent('vacancy_block', action);
      },
      onClose() {
        this.show = false;
        this.logEvent('close');

        if (this.isGuest) {
          this.guestCloseBannerBehaviour();
          return;
        }

        this.userCloseBannerBehaviour();
      },

      getGuestClosedBanners() {
        const dataFromCookies = Cookie.get(GUEST_COOKIE_NAME);

        try {
          return JSON.parse(dataFromCookies) || [];
        } catch (e) {
          return []
        }
      },

      userCloseBannerBehaviour() {
        axiosInstance.post(this.currentVacancy.closeBannerHref);
      },

      guestCloseBannerBehaviour() {
        const closedBannersIds = this.getGuestClosedBanners();
        const {id} = this.currentVacancy;
        const result = [...closedBannersIds, id];

        Cookie.set(GUEST_COOKIE_NAME, result)
      },

      handleTrackSlideView() {
        if (this.trackingTimeoutId) clearTimeout(this.trackingTimeoutId);

        this.trackingTimeoutId = setTimeout(this.trackSlideView, TRACKING_TIMEOUT)
      },

      trackSlideView() {
        this.trackingTimeoutId = null;
        const { trackHref } = this.currentVacancy;

        if (trackHref) axiosInstance.post(trackHref);
      },

      initSwiper() {
        if (this.vacancies.length < 2) return;

        this.swiperInstance = new Swiper(this.$el, {
          modules: [Pagination, Navigation],
          autoHeight: true,
          navigation: {
            nextEl: this.$refs.next,
            prevEl: this.$refs.prev,
          },
          pagination: {
            el: this.$refs.pagination,
          },
          loop: true,
          slidesPerView: 1,
        });

        this.swiperInstance.on('slideChange', ({ realIndex }) => {
          this.currentIndex = realIndex;
        });
      },
    },
    computed: {
      currentVacancy() {
        return this.vacancies[this.currentIndex];
      }
    },
  };
</script>