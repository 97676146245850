import { initMultiselect } from "src/global-helpers.js";

$(document).on('click', '.js-multiselect .selected_options .option .remove', function () {
  $(this).closest('.option').remove();
  $('.js-multiselect input.text').trigger('change');
  return false
});

$(function () {
  initMultiselect();
});

