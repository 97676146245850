<template>
  <div
    v-if="hasIcons"
    class="company-icons"
  >
    <BasicTooltip
      v-if="isAccreditedCompany"
      :max-width="304"
      content="Компания сообщила, что находится в реестре аккредитованных IT-организаций Минцифры"
      placement="top"
    >
      <span class="company-icons__icon">
        <SvgIcon
          icon="accredited"
          size="16"
          :color="accreditedColor"
        />
      </span>
    </BasicTooltip>
    <BasicTooltip
      v-if="isHabrMember"
      content="Участник сообщества «Хабр»"
      placement="top"
    >
      <span class="company-icons__icon company-icons__icon_habr">
        <SvgIcon
          source="/images/social-ratings.svg"
          icon="social-rating-habr-h"
          size="8"
        />
      </span>
    </BasicTooltip>
  </div>
</template>

<script>
  import BasicTooltip from '../../BasicTooltip/BasicTooltip.vue';
  import SvgIcon from '../../icons/SvgIcon/SvgIcon.vue';

  export default {
    name: 'CompanyIcons',
    components: { SvgIcon, BasicTooltip },
    props: {
      company: {
        type: Object,
        required: true,
      },
      accreditedColor: {
        type: String,
        default: 'light-blue',
      },
    },
    computed: {
      isHabrMember() {
        return this.company.hint === 'habr' || this.company.habrahabr_info;
      },
      isAccreditedCompany() {
        return this.company.accredited;
      },
      hasIcons() {
        return this.isHabrMember || this.isAccreditedCompany
      }
    }
  };
</script>

<style scoped>

</style>