/* prettier-ignore */
/* eslint-disable */

import { initTooltips } from "src/components/BasicTooltip/BasicTooltip.js";
import {
  clear_form_error, customize_select_wsearch,
  customize_select, init_category_selector,
  init_wysiwyg,
  initRedactor,
  location_field_customize, setPopupPosition,
  stickyButtonsInit, country_field_customize,
  setCaretPosition,
  notify,
  initPhoneMasks,
} from "src/global-helpers.js";

if (process.env.NODE_ENV === 'development') {
  // import('src/data/mockApi.js').then(res => res.createMockServer());
}

let lastMouseDownTarget = null;

function initClipboardBtn() {
  const clipboardBtns = $('.js-clipboard');
  let targetInputValue;
  clipboardBtns.on('click', (event) => {
    event.preventDefault();
    const btn = $(event.currentTarget);
    const { target, prefix, type } = btn.data();
    if (type == 'text') {
      targetInputValue = $(`[name="${target}"]`).text();
    } else {
      targetInputValue = $(`[name="${target}"]`).val();
    }

    const valueForCopy = (prefix ? prefix : '') + targetInputValue

    if (typeof targetInputValue === 'undefined') return;

    navigator.clipboard.writeText(valueForCopy).then(() => {
      if (type == 'text') {
        notify('Промокод скопирован');
      } else {
        notify('Ссылка скопирована');
      }
    });
  })
}

$(function() {

  // заменим стандартные селектбоксы - плагином chosen
  // чтобы не захламлять код?
  const select = $('.js-default-select');
  if (select.length) {
    customize_select(select);
  }

  const select_wsearch = $('.js-select-wsearch');
  if (select_wsearch.length) {
    customize_select_wsearch(select_wsearch);
  }

  // инициируем стандартный визивиг для всех текстареа
  const textarea = $('.js-textarea-redactor');
  if (textarea.length) {
    init_wysiwyg(textarea);
  }

  const textareaWi = $('.js-textarea-wi-redactor');
  if (textareaWi.length) {
    init_wysiwyg_with_images(textareaWi);
  }

  // Во всех длинных формах, необходимо, чтобы панель с кнопками сабмита прилипала к нижней части экрана,
  // если размер формы больше размера экрана

  $('.js-sticky-buttons').each(stickyButtonsInit);

  $.ajaxSetup({ cache: false });

  $('textarea.text').autosize();

  const moneyInput = $('input.money');
  if (moneyInput.length) {
    import('src/libs/jquery.mask.js').then(() => {
      moneyInput.mask('000000000000000');
    });
  }
  const datepicker = $('.datepicker');
  if (datepicker.length) {
    import('src/parts/datepicker.js').then(({ initDatepicker }) => {
      datepicker.each((index, el) => {
        initDatepicker(el);
      });
    });
  }
  initPhoneMasks();

  /**
   * Функция инициализирует предпросмотр изображений для элементов форм
   */
  $('.image_uploader, .banner_uploader').each(function () {
    image_uploader(this);
  });


  $('.location-field').each(function (index, location_field) {
    location_field_customize($(location_field));
  });

  $(".country-field").each(function (index, country_field) {
    country_field_customize($(country_field));
  });

  $('.category_selector').each(function () {
    init_category_selector($(this));
  });

  if ($('[data-lightbox]').length) {
    import('src/libs/lightbox');
  }

  initTooltips();
  initClipboardBtn();


  $('.js-help-part-select').on('change', function () {
    window.location = $(this).children("option:selected").data('url');
  });

  $('[data-return]').on('click', function () {
    $.cookie('return_to', $(this).data('return'), { expires: 1 });
  });

  $('[data-mpe]').on('click', function () {
    const ev = $(this).data('mpe');
    setTimeout(function () {
      $.ajax({
        url: '/mpt',
        data: { ev: ev },
        method: 'POST',
        dataType: 'json'
      });
      return false;
    }, 20);
  });

  $('.hide_cr_notice').on('click', function () {
    $(this).closest('.top_notice').fadeOut();
  });

  $('.close-poll').on('click', function () {
    $(this).closest('.vacancy-poll').remove();
  });

  $('.js-expand-control').on('click', function () {
    const wrapper_class = $(this).data('wrapper');
    $(this).closest('.separator').remove();
    $('.' + wrapper_class).find('.info').unwrap();
  });

  $(document).on('click', '.notification_inner .notification_inner_close', function (e) {
    const cname = $(this).data('cname');
    $.cookie(cname, 1, { expires: 40 });
    $(this).closest('.notification_inner').remove();
  });
  // это используется на списке компаний - фильтр в сайдбаре
  $(document).on('change', '.category_select', function () {
    const id = $(this).val();
    const category_select_wrapper = $(this).closest('.category_select_wrapper');

    if (id) {
      $.get('/suggest/categories/' + id, function (json) {

        const subcategories = json.category.children;
        const options = [$('<option value="">Любая</option>')];
        for (let k in subcategories) {
          const subcategory = subcategories[k];
          options.push($('<option value="' + subcategory.id + '"></option>').text(subcategory.name));
        }

        category_select_wrapper.find(".subcategory_select").html(options).attr('disabled', false).trigger("change").trigger("liszt:updated");

      });
    }
  });

  $(document).on('nested:fieldAdded', function (event) {
    const field = event.field;

    // кастомные селекты
    customize_select(field.find('.js-default-select'));

    // кастомные инпуты типа "location"
    field.find('.location-field').each(function (index, location_field) {
      location_field_customize($(location_field));
    });

  });


  $(document).on('click', '.change_categories', function () {
    const input = $(this);
    const field = input.closest('.field');
    const popup = field.find('.global_popup');
    popup.addClass('opened');
    setPopupPosition(popup);
    $('body').addClass('noscroll');
    return false;
  });

  $(document).on('mousedown', (e) => {
    lastMouseDownTarget = e.target;
  });

  $(document).on('click', '.global_popup', function (e) {
    if (lastMouseDownTarget !== e.target) return;

    if ($(e.target).hasClass('global_popup')) {
      $(e.target).removeClass('opened');
      $('body').removeClass('noscroll');
      if ($(e.target).hasClass('courses_filter')) {
        $('.js-courses-search-filter-form').trigger('change');
      }
    }
  });

  $(document).on('click', '.global_popup .close_popup, .global_popup .close_popup_button', function (e) {
    $(this).closest('.global_popup').removeClass('opened');
    $('body').removeClass('noscroll');
    if ($(this).closest('.global_popup').hasClass('courses_filter')) {
      $('.js-courses-search-filter-form').trigger('change');
    }
  });

  $(document).on('keyup', function (e) {
    if (e.keyCode === 27) {
      $('.global_popup').removeClass('opened');
      $('body').removeClass('noscroll');
      if ($('.global_popup').hasClass('courses_filter')) {
        $('.js-courses-search-filter-form').trigger('change');
      }
    }
  });

  // TODO: remove
  // добавляем utm метки на ссылки которые надо отслеживать :)
  $(document).on('mousedown', 'a[data-utm]', function () {
    const link = $(this);
    const url = link.attr('href') + link.data('utm');
    link.attr('href', url);
  });

  // дропдауны с меню
  $(document).on('click', '.dropdown_simple .toggler', function (e) {
    e.stopPropagation();
    const dropdown = $(this).closest('.dropdown_simple');

    if (dropdown.hasClass('open')) {
      $('.dropdown_simple').removeClass('open');
    } else {
      $('.dropdown_simple').removeClass('open');
      dropdown.addClass('open');
    }

    $(document).one('click', function (e) {
      // if ($(e.target).closest('.dropdown_simple').length) return false;
      if ($(e.target).hasClass('dropdown_ext_toggler')) return false;

      $('.dropdown_simple').removeClass('open');
    });
  });


  // дропдауны инлайн
  $(document).on('click', '.dropdown_inline .toggler', function (e) {
    e.stopPropagation();
    const dropdown = $(this).closest('.dropdown_inline');

    if (dropdown.hasClass('open')) {
      $('.dropdown_inline').removeClass('open');
    } else {
      $('.dropdown_inline').removeClass('open');
      dropdown.addClass('open');
    }
  });

  $(document).on('click', function (e) {
    const close = $(e.target).closest('.dropdown_inline').length === 0;
    if (close) {
      $('.dropdown_inline').removeClass('open');
    }
  });

  window.helpers.init_fields_with_counter();

  // Оверлей - механизм затемнения фона
  const overlay = $('<div id="overlay"></div>');

  // Показать оверлей
  $(document).on('show_overlay', function (event, options) {
    $('body').append(overlay).addClass('overlay_show');
    if (typeof (options) !== 'undefined' && typeof (options) == 'object') {
      if (typeof (options.opacity) !== 'undefined' && options.opacity === true) overlay.addClass('opacity');
      if (typeof (options.close_by_click) !== 'undefined' && options.close_by_click === true) {
        overlay.on('click', function () {
          $(document).trigger('hide_overlay');
          if (typeof (options.onclose) !== 'undefined' && typeof (options.onclose) == 'function') {
            options.onclose();
          }
        });
      }
    }
  });

  // добавляет закрытие по клику
  $(document).on('on_click_overlay', function (event, callback) {
    overlay.one('click', function () {
      callback();
    });
  });

  // Скрыть оверлей
  $(document).on('hide_overlay', function (event) {
    $('body').removeClass('overlay_show');
    overlay.remove();
  });

  // по клику на сабмит кнопку - надо ее дизеблить и добавлять класс disabled и loading
  $(document).on('click', 'form input[type="submit"].button, form input[type="button"].button, form button.button', function () {
    const self = $(this);
    self.addClass('loading disabled');
    //    return false;
    setTimeout(function () {
      self.prop('disabled', true);
    }, 1);
  });

  $(document).on('click', 'a[data-remote="true"]', function () {
    const self = $(this);
    self.addClass('loading disabled');
    self.bind('ajax:complete', function () {
      self.removeClass('loading').removeClass('disabled');
    });
  });

  // когад форма успешно засабмитилась, то надо раздизеблить все кнопки которые с классом loading. и убрать класс loading.
  $(document).bind('ajax:success ajax:error ajax:complete', "form", function () {
    $('.button.loading', this).removeClass('loading disabled').prop('disabled', false);
  });

  // очистка ошибок по нажатия кнопок
  $(document).on('keydown', 'input, textarea', function (e) {
    if ($(this).parents('.field_error').length) {
      clear_form_error($(this).parents('.field_error'));
    }
  });

  $(document).on('change', 'select', function (e) {
    if ($(this).parents('.field_error').length) {
      clear_form_error($(this).parents('.field_error'));
    }
  });

  $(document).on('input', 'input[type="email"]', function (e) {
    const form = $(this).parents('.standart_form');

    if (form.find(".field_error:not(.hidden):not(:empty)").length) return;

    form.find('button[type="submit"]').removeAttr('disabled');
  });

  // очистка ошибок в обязательных чекбоксах
  $(document).on('change', 'input[type="checkbox"]', function (e) {
    if ($(this).parents('.field_error').length) {
      clear_form_error($(this).parents('.field_error'));
    }
  });

  $(document).on('change', 'input[type="radio"]', function (e) {
    if ($(this).parents('.field_error').length) {
      clear_form_error($(this).parents('.field_error'));
    }
  });

  $(document).on('click', '.clear_suggest', function (e) {
    const field = $(this).closest('.field');
    field.find('input').removeAttr('checked')
      .removeAttr('selected')
      .not(':button, :submit, :reset, :radio, :checkbox')
      .val('');
    field.find('.category_item').removeClass('activated');
    field.find('.category_selector.form .button').attr('disabled', 'disabled');
    if ($(this).hasClass('clear_input')) {
      $(this).closest('.field').find('input').removeAttr('checked').removeAttr('selected').prop('checked', false);
    }
  });
});


function init_wysiwyg_with_images($textarea) {
  const csrf_token = $('meta[name=csrf-token]').attr('content');
  const csrf_param = $('meta[name=csrf-param]').attr('content');
  const params = {};
  if (csrf_param && csrf_token) {
    params[csrf_param] = encodeURIComponent(csrf_token);
  }
  initRedactor().then(() => {
    const redactor = $textarea.redactor({
      imageUpload: '/link/redactor_image',
      buttons: ['html', 'format', 'bold', 'italic', 'ul', 'ol', 'link', 'image'],
      formatting: ['p', 'h3'],
      allowedTags: ['p', 'h3', 'ul', 'li', 'ol', 'b', 'strong', 'em', 'i', 'del', 'em', 'a', 'iframe', 'img'],
      tabindex: 2,
      plugins: ['video'],
      tabFocus: false,
      minHeight: '106px',
      autoresize: false,
      cleanSpaces: false,
      toolbarContext: false,
      autoparseLinks: false,
      autoparseImages: false,
      autoparseVideo: false,
      clipboardUpload: false,
      lang: 'ru',
      callbacks: {
        keyup: function (e) {
          clear_form_error($(this.source.getElement().nodes[0]).closest('.field_error'));
        }
      }
    });
    redactor.on('image.uploadError', ({ error }) => {
      notify(error, 'error');
    })
  });
}

const tests = {
  filereader: typeof FileReader != 'undefined',
  dnd: 'draggable' in document.createElement('span'),
  formdata: !!window.FormData
};

const acceptedTypes = {
  'image/png': true,
  'image/jpeg': true,
  'image/gif': true
};

function previewfile(file, field) {
  const dropzone = field.find('.dropzone');
  if (tests.filereader === true && acceptedTypes[file.type] === true) {
    const reader = new FileReader();
    reader.onload = function (event) {
      if (dropzone.hasClass('background')) {
        dropzone.css('backgroundImage', 'url(' + event.target.result + ')');
      } else {
        const image = new Image();
        image.src = event.target.result;
        dropzone.html(image);
      }
      field.addClass('has_image');
    };
    reader.readAsDataURL(file);
  }
}

// добавляет префикс
// add_remove_prefix('company[avatar]');
// ==>
// company[remove_avatar]
function add_remove_prefix(field_name) {
  const r = /^(.+)\[(.+)\]$/;
  return field_name.replace(r, "$1[remove_$2]");
}

function readfiles(files, field) {
  for (let i = 0; i < files.length; i++) {
    previewfile(files[i], field);
  }
  field.find('.remove_field').remove();
}

function clear_preview(container) {
  const dropzone = container.find('.dropzone');
  const defaultUrl = container.data('default_image') || '';

  if (dropzone.hasClass('background')) {
    dropzone.css('backgroundImage', `url(${defaultUrl})`);
  } else {
    const image = new Image();
    image.src = defaultUrl;
    dropzone.html(image);
  }

  container.removeClass('has_image');
}

function image_uploader(el) {
  const field = $(el);
  const dropzone = field.find('.dropzone');
  const input_file = field.find('.controls_buttons input[type="file"]');
  const delete_btn = field.find('.controls_buttons .delete_btn');
  const upload_btn = field.find('.controls_buttons .upload_btn');
  const field_name = field.find('.controls_buttons input[type="file"]').attr('name');

  if (tests.dnd) {
    dropzone.on('dragover', function () {
      dropzone.addClass('hover');
      return false;
    });
    dropzone.on('dragend', function () {
      dropzone.removeClass('hover');
      return false;
    });
    dropzone.on('drop', function (e) {
      dropzone.removeClass('hover');
      e.preventDefault();
      readfiles(e.originalEvent.dataTransfer.files, field);
    });
  }

  // эмуляция клика по инпуту, при клике на кнопку "загрузить"
  upload_btn.on('click', function(evt) {
    evt.preventDefault();
    $(this).closest('.controls_buttons').find('input[type="file"]').trigger('click');
  });

  // покажем предпросмотр, после того как файлы выбраны
  input_file.on('change', function () {
    if (this.files.length > 0) {
      readfiles(this.files, field);
    } else {
      clear_preview(field);
    }
  });

  // удаление фотки
  delete_btn.on('click', function () {
    clear_preview(field);

    // set marker for deleting image from db-model
    $(field).append('<input type="hidden" class="remove_field" value="1" name="' + add_remove_prefix(field_name) + '"/>');
    return false;
  });

}
