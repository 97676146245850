import 'jquery-ujs';
import 'jquery.cookie';

import 'src/bootstrap.js';
import 'src/libs/jquery-nested-form.js';
import 'src/libs/jquery.autosize.js';
import 'src/libs/jquery.scrollTo.js';
import 'src/parts/quick-responsivnes.js';
import * as helpers from 'src/global-helpers.js';

import 'src/libs/remotipart';

import 'styles/legacy_responsive.sass';

(() => {
  window.$ = jQuery;
  window.jQuery = jQuery;
  window.helpers = helpers || {};
  // eslint-disable-next-line no-underscore-dangle
  window.__COMMIT_HASH__ = __COMMIT_HASH__;
})();
