import { createVueApp } from 'src/utils/vue.js';

import VacancyBannerSlider from 'src/components/vacancies/VacancyBannerSlider/VacancyBannerSlider.vue';

export function mountVacancyBanner(ssrState) {
  const bannerPlace = document.querySelector('[data-vacancy-banner]');

  if (!ssrState || !bannerPlace) return;

  const { vacancyBanners, isGuest } = ssrState;
  const bannerApp = createVueApp({
    render(h) {
      return h(VacancyBannerSlider, { props: { vacancies: vacancyBanners, isGuest } });
    },
  });

  bannerApp.$mount(bannerPlace);
}