<template>
  <BasicLink
    :href="rating.href"
    underline="none"
    target="_blank"
  >
    <BasicTooltip
      :content="rating.title"
      placement="top"
    >
      <BasicText
        weight="bold"
        size="small"
        :color="color"
      >
        <InlineAlign
          gap="small"
          align="center"
        >
          <template #start>
            <SvgIcon
              icon="star"
              :color="color"
              size="14"
            />
          </template>
          <template #default>{{rating.value}}</template>
        </InlineAlign>
      </BasicText>
    </BasicTooltip>
  </BasicLink>
</template>

<script>
  import BasicLink from 'src/components/BasicLink/BasicLink.vue';
  import BasicTooltip from 'src/components/BasicTooltip/BasicTooltip.vue';
  import SvgIcon from 'src/components/icons/SvgIcon/SvgIcon.vue';
  import InlineAlign from 'src/components/InlineAlign/InlineAlign.vue';
  import BasicText from 'src/components/text/BasicText/BasicText.vue';

  export default {
    name: 'CompanyRating',
    components: { BasicLink, SvgIcon, InlineAlign, BasicTooltip, BasicText },
    props: {
      color: {
        type: String,
        default: 'emphasized',
      },
      rating: {
        type: Object,
        required: true,
      }
    }
  }
</script>