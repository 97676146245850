import 'blueimp-file-upload';
import tmpl from 'blueimp-tmpl';
import 'jquery-ui/ui/widgets/sortable';
// import 'jquery-fileupload/basic';
// import 'jquery-fileupload/vendor/tmpl';

$(function() {

  var sort_company_cp_photos_url = $('#sort_company_cp_photos_url').val();

  $('#dropzone .upload_btn').on('click', function(){
    $('#dropzone input[type="file"]').click();
    return false;
  });

  var getItemsPositions = function(){
    var ids = [];
    $( ".files_list .file" ).each(function(i, file){
      ids.push( $(file).data('id') );
    });
    $.post(sort_company_cp_photos_url, { items: ids });
  };

  $('.files_list').sortable({
    placeholder: "ui-state-highlight",
    cancel: "a,button",
    //containment: "parent",
    cursor: "move",
    forceHelperSize: true,
    forcePlaceholderSize: true,
    items: "> .file",
    update: getItemsPositions
  });

  $('#company_photo_photo').fileupload({
      dataType: "json",
      acceptFileTypes: /(\.|\/)(gif|jpe?g|png)$/i,
      previewSourceFileTypes: /^image\/(gif|jpeg|png)$/,
      limitMultiFileUploads: 3,
      limitConcurrentUploads: 3,
      dropZone: $('#dropzone'),
      add: function(e, data) {
        var file = data.files[0];

        if (file.size < 26214400) {
          var tpl = tmpl("template-upload", file);
          data.context = $(tmpl("template-upload", file));
          $('.files_list').append(data.context);
          return data.submit();
        } else {
          file.error_text = 'Файл больше 25 Mb';
          data.context = $(tmpl("template-fail", file));
          $('.files_list').append(data.context);
          return false;
        }
      },
      progress: function(e, data) {
        var progress;
        if (data.context) {
          progress = parseInt(data.loaded / data.total * 100, 10);
          return data.context.find('.bar').css('width', progress + '%');
        }
      },
      done: function(e, data) {
        if (data.result.status == 'success'){
          data.result.name = data.files[0].name;
          data.context.replaceWith(tmpl("template-success", data.result));
        }else{
          data.result.name = data.files[0].name;
          data.context.replaceWith(tmpl("template-fail", data.result));
        }
      },
      fail: function(e, data) {
        var file = data.files[0];
        data.context.replaceWith(tmpl("template-fail", file));
      }
    });

  $(document).bind('drop dragover', function(e) {
    return e.preventDefault();
  });

  $(document).bind('dragover', function(e) {
    var dropZone, timeout;

    dropZone = $('#dropzone');
    timeout = window.dropZoneTimeout;
    if (!timeout) {
      dropZone.addClass('in');
    } else {
      clearTimeout(timeout);
    }
    if (e.target === dropZone[0]) {
      dropZone.addClass('hover');
    } else {
      dropZone.removeClass('hover');
    }
    return window.dropZoneTimeout = setTimeout(function() {
      window.dropZoneTimeout = null;
      return dropZone.removeClass('in hover');
    }, 100);
  });
});
