/**
 * Селектор выбора сферы деятельности. Испольузется в сайдбаре в блоке с фильтрами на странице со списком вакансий,
 * со скписком резюме, на странице создания вакансии и редактирования профиля пользователя + онбординг пользователя.
 */
$(document).on('change', '.js-divisions-selector input', toggleDivisions);
$(document).on('change', '.js-divisions-selector input', hideDivisionsError);

$(function() {
  $('.js-divisions-selector input').each(toggleDivisions)
});

function hideDivisionsError() {
  var selector = $(this).closest('.js-divisions-selector');
  selector.find('.validation-error').remove();
}

function toggleDivisions() {
  var selector = $(this).closest('.js-divisions-selector');
  var max = selector.attr('data-max_items') * 1;
  var checked = selector.find(':checked').length;

  if (checked >= max) {
    selector.find('input:checkbox:not(:checked)').attr('disabled', true)
  } else {
    selector.find('input:checkbox').attr('disabled', false)
  }
}

