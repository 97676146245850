import 'src/libs/jquery.collagePlus.js';
import 'src/libs/imagesloaded.js';

$(function(){
  
  if(!$('#company_photos').length) { 
    return false;
  }

  function collage() {
    $('#company_photos img').css('opacity', 1);
    $('#company_photos').collagePlus();
  }

  $('#company_photos').imagesLoaded(collage);
    
  var resizeTimer = null;
  $(window).bind('resize', function() {
      // hide all the images until we resize them
      // set the element you are scaling i.e. the first child nodes of ```.Collage``` to opacity 0
      $('#company_photos img').css("opacity", 0);
      // set a timer to re-apply the plugin
      if (resizeTimer) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(collage, 200);
  });
    

  


});