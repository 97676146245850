import { university_field } from "src/global-helpers.js";

$(function(){
  if(!$('.js-education-course-form').length) return;

  if($('.university-field').length){
    university_field($('.university-field'));
  }

  $(document).on('keyup', '#education_course_old_price', recalcDiscount);
  $(document).on('keyup', '#education_course_price', recalcDiscount);
  $(document).on('change', '#education_course_price_unit', setCurrency);
  $(document).on('change', '#education_course_installment_possible', checkInstallment);
  $(document).on('keyup', '#education_course_installment_duration', recalcInstallment);

  function recalcDiscount(){
    let price = parseInt($('#education_course_price').val(), 10);
    let oldPrice = parseInt($('#education_course_old_price').val(), 10);
    if (price > 0 && oldPrice > 0) {
      let discount = Math.ceil((oldPrice  - price) / oldPrice * 100);
      if (discount > 0) {
        $('#education_course_discount').val(discount);
      } else {
        $('#education_course_discount').val(0);
      }
    } else {
      $('#education_course_discount').val('');
    }
    recalcInstallment();
  }

  function setCurrency(){
    let currency = $( "#education_course_price_unit option:selected").text()
    $('.currency_unit').text(currency);
  }

  function checkInstallment(){
    let installmentEnabled = $("#education_course_installment_possible").prop('checked');
    if (installmentEnabled) {
      $('.installment_settings').removeClass('hidden');
    } else {
      $('.installment_settings').addClass('hidden');
    }
    recalcInstallment();
  }

  function recalcInstallment(){
    let installmentEnabled = $("#education_course_installment_possible").prop('checked');
    
    if (!installmentEnabled) {
      return false;
    }

    let duration = parseInt($('#education_course_installment_duration').val(), 10);
    if (duration < 1) {
      duration = 1
      $('#education_course_installment_duration').val(duration);
    }
    let price = parseInt($('#education_course_price').val(), 10);

    if (price > 0 && duration > 0) {
      let monthlyPayment = Math.ceil(price / duration);
      $('#education_course_installment_payment').val(monthlyPayment);
    } else {
      $('#education_course_installment_payment').val('');
    }
  }
});