import { initSkills, setMaxNested } from "src/global-helpers.js";
import { debounce } from "src/utils/debounce.js"

$(function() {
  // salary suggestion

  const container = $('.js-personal-form,.js-specialization-form');
  if (!container.length) return;

  const specSelector = container.find('.specs-selector');
  if (!specSelector.length) return;

  const skillSelector = container.find('.skills-list');
  if (!skillSelector.length) return;

  const qualificationEl = container.find('select[name="user[salary_qualification_id]"]');
  if (!qualificationEl.length) return;

  const userSalary = container.find('input[name="user[salary]"');

  const debouncedHandler = debounce(function() {
    // qualification
    const qualification = qualificationEl.val();

    // specialization
    const specs = specSelector
      .find('input[type="checkbox"]:checked')
      .map(function(_, el) {
        return el.value;
      }).get();

    // skills
    const skills = [];
    skillSelector.find('input[name="user[user_skills_ids][]"]').each(function(_, el) {
      if (el.value) {
        skills.push(el.value)
      }
    });

    // do not recommend when no skills or specs set
    if (skills.length <= 0 || specs.length <= 0) return;

    $.ajax({
      url: '/salaries/recommendations',
      data: {
        q: qualification,
        specs: specs,
        skills: skills.slice(0, 3)
      },
      dataType: 'script'
    });
  }, 500);

  // set-up event listeners
  qualificationEl
    .on('change', debouncedHandler);

  specSelector
    .on('change', 'input[type=checkbox]', debouncedHandler);

  $(document)
    .on('reloadSalary', debouncedHandler);

  if (userSalary.val().trim().length <= 0) {
    // hide recommendation on init
    debouncedHandler();
  }
});

$(function() {
  var el = document.querySelector('.step.active');
  if (el) {
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
  }
});

$(function() {
  if(!$('[data-onboarding]').length) return;

  initSkills();

  setMaxNested(2, $('#add_phone'),$('#ihave_phones'));
  setMaxNested(2, $('#add_email'),$('#ihave_emails'));

  $('#experience_work_now').on('change', function(event) {
    if ($(this).prop('checked')) {
      $(this).closest('.field').find('.end_date').addClass('hidden');
      $(this).closest('.field').find('.end_date input').attr('disabled', false);
      $(this).closest('.field').find('.end_date select').attr('disabled', true).trigger("liszt:updated");
    } else {
      $(this).closest('.field').find('.end_date').removeClass('hidden');
      $(this).closest('.field').find('.end_date input').attr('disabled', false);
      $(this).closest('.field').find('.end_date select').attr('disabled', false).trigger("liszt:updated");
    }
  });

  $('[data-status]').on('click', function(event) {
    if ($(this).data('status') == 'closed') {
      requestAnimationFrame( () => {
          $('textarea.text:not(.js-textarea-redactor)').removeAttr('style')
          $('textarea.text:not(.js-textarea-redactor)').autosize()
      });
      $(this).attr('data-status', 'opened');
      $(this).data('status', 'opened');
      $(this).closest('.section').find('.field.additional').removeClass('hidden');
    } else {
      $(this).attr('data-status', 'closed');
      $(this).data('status', 'closed');
      $(this).closest('.section').find('.field.additional').addClass('hidden');
   }
  });

  $('span.calendar').on('click', function(event) {
    $(this).closest('.input').find('.datepicker').click();
  });

  const debouncedNotificationForm = debounce(function() {
    $('.js-onboard-notifications-form').submit();
  }, 1000);

  $('.js-onboard-notifications-form input[type="checkbox"]').on('change', function(event) {
    debouncedNotificationForm();
  });
});