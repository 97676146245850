$(function(){
  $(document).on('click', '[data-show-target]', function() {
    const $toShow = $(`[data-target=${this.dataset.showTarget}]`);
    $toShow.removeAttr('hidden');
  });

  $(document).on('click', '[data-hide-target]', function() {
    const $toHide = $(`[data-target=${this.dataset.hideTarget}]`);
    $toHide.attr('hidden', true);
  });

  $(document).on('click', '[data-remove-target]', function() {
    const $toRemove = $(`[data-target=${this.dataset.removeTarget}]`);
    $toRemove.remove();
  });
});