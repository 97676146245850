$(function(){
  $('.templates_popup_wrapper .select_template').on('click', function(){
    var template = $(this).data('template');
    $('#message_body').autosize({append: ""}).trigger('keyup').val(template);
    window.setTimeout(function(){
      $("#message_body").height(0).height($("#message_body")[0].scrollHeight - 16).change();
    }, 1);
    $('#message_body').focus();
    $('.new_message_form button').prop('disabled', false);
  });

  $('body').on('click', function(e){
    $('.templates_popup_wrapper').addClass('hidden');
  });

  $('.templates_popup_toggle_wrapper').on('click', function(e){
    if (!$(e.target).hasClass('url')) {
      e.stopPropagation();
    }
    $('.templates_popup_wrapper').toggleClass('hidden');
  });

  if($('.templates_list').length === 0){ return; }
  
  // высота окна с перепиской
  function calc_templates_height(){
    var templates_list = $('.conversation_templates_page .templates_list');
    var discussions = $('.discussions');
    var templates_list_top_offset = templates_list.offset().top;
    var window_height = $(window).height();
    var buttons_height = $('.conversation_templates_page .footer').height() || 42;

    var templates_list_height = window_height - templates_list_top_offset - buttons_height;
    templates_list_height = templates_list_height < 400 ? 400 : templates_list_height;
    templates_list.css('height', (templates_list_height - 51)+'px');  

    var templates_height = $('.section.conversation_templates').outerHeight();
    var search_discussions_height = $('.search_discussions').outerHeight(true);
    var header_height = $('.section.discussions_list .head').outerHeight(true);

    var discussions_height = templates_height - header_height - search_discussions_height - 37;
    discussions.css('height', (discussions_height)+'px');

    setTimeout(calc_templates_height, 10);
  }
  calc_templates_height();
});
