import { setPopupPosition } from "src/global-helpers.js";

$(document).on('click', '.endorse_or_disapprove_link_wrapper .count', function(){
  
  // $(this).closest('.endorse_or_disapprove_link_wrapper').find('global_popup').addClass('opened');


  var link_wrapper = $(this).closest('.endorse_or_disapprove_link_wrapper');
  // if(link_wrapper.hasClass('open_popup')){
  //   $('.endorse_or_disapprove_link_wrapper').removeClass('open_popup');
  // }else{
  //   $('.endorse_or_disapprove_link_wrapper').removeClass('open_popup');
  //   link_wrapper.addClass('open_popup');  
  // }
  
  if ( !$(this).hasClass('clicked') && link_wrapper.find('.global_popup').length === 0){
    $(this).addClass('clicked');
    var url = link_wrapper.data('endorsers_path');
    $.get(url);
  }else{
    var popup = link_wrapper.find('.global_popup');
    popup.addClass('opened');
    $('body').addClass('noscroll');
    setPopupPosition(popup);
  }
});

$(document).on('click', '#like_link_wrapper .count', function(){
  var link_wrapper = $(this).closest('#like_link_wrapper');
  link_wrapper.find('a').click();
});
