<template>
  <span class="inline-align" :class="bem('inline-align', { gap, plain, align })">
    <span
      v-if="$slots.start"
      class="inline-align__aside inline-align__aside--start"
    >&#8203;<slot name="start"
    /></span><slot /><span
      v-if="$slots.end"
      class="inline-align__aside inline-align__aside--end"
    >&#8203;<slot name="end" /></span>
  </span>
</template>

<script>
  import { bem } from 'src/utils/bem.js';

  export default {
    name: 'InlineAlign',
    props: {
      gap: String,
      align: String,
    },
    methods: {
      bem,
    },
    computed: {
      plain() {
        const { start, end } = this.$slots;
        return !start && !end;
      },
    }
  }
</script>