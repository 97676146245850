import { mountVacancyBanner } from 'src/parts/vacancy_banner.js';
import { getSsrState } from 'src/utils/ssr.js';

$(function(){
  mountVacancyBanner(getSsrState())

  if(!$('.salaries_graph_wrapper').length) return;

  const url = $('.salaries_graph_wrapper').data('url');
  $.ajax({
    type: 'GET',
    url,
    datatype: 'script'
  });
});
