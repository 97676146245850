import { skill_field_customize, company_field, setPopupPosition, setArsenalSearchUrl } from "src/global-helpers.js";

$(function(){
  // если блока нет на странице, то код дальше можно не выполнять.
  if( $('.js-arsenal-search-filter-form').length === 0 ) return;

  // обработчик сабмита формы в правом сайдбаре
  var timer;
  $(document).on('change', '.js-arsenal-search-filter-form', function(){
    clearTimeout(timer);
    timer = setTimeout(function(){
      setArsenalSearchUrl();
      // сабмитим форму
      $('.js-arsenal-search-filter-form').submit();

    }, 300);
  });

  $(document).on('click', '.specialization_name', function(){
    var input = $(this);
    var field = input.closest('.field');
    var popup = field.find('.global_popup');
    popup.find('.close_popup_button').removeClass('disabled loading');
    popup.find('.close_popup_button').prop('disabled', false);
    popup.addClass('opened');
    setPopupPosition(popup);
    $('body').addClass('noscroll');
    return false;
  });

  skill_field_customize($('.skill-field'));
  company_field($('.company-field'));
});
