import 'tippy.js/dist/tippy.css';
import './BasicTooltip.sass';
import tippy from 'tippy.js';

function initTippy(el) {
  const options = JSON.parse(el.dataset.tippy);
  tippy(el, options);
}

export const initTooltips = () => {
  $('[data-tippy]').each((index, el) => {
    initTippy(el);
  });

  $(document).on('mouseover', '[data-tippy]', function() {
    if (this && this._tippy) return;
    initTippy(this);
  });
};