import 'jquery-ui/ui/widgets/autocomplete';
import { clear_form_error, ed_platform_field, educationCourseField, initSkills, university_field } from "src/global-helpers.js";

function initChosen() {
  var input = $('.end_date');
  var month = input.find('select').first();

  month.on('change', toggleYear);
  toggleYear();

  function toggleYear() {
    var year = input.find('.chzn-container').last();

    if (!month.val()) {
      year.hide();
    } else {
      year.show();
    }
  }
}

$(function(){
  
  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-educations-form').length) return;

  initChosen();
  
  // колледжи
  if($('.college-field').length){
    college_field($('.college-field'));

    // $('.location-field .city_id').on('change', function(){
    //   $('.college-field .college').val( '' );
    //   $('.college-field .college_id').val( '' );
    //   $('input.faculty').val('');
    // });

    $('.college-field .college_id').on('change', function(){
      $('input.faculty').val('');
    });
  }

  // универы
  if($('.university-field').length){
    university_field($('.university-field'));

    // если в поле "город" произошли изменения
    // $('.location-field .city_id').on('change', function(){
      
    //   // сбросим универы
    //   $('.university-field .university').val( '' );
    //   $('.university-field .university_id').val( '' );

    //   // сбросим факультеты
    //   var faculty_id = $('.faculty-field .faculty_id');
    //   faculty_id.html('');
    //   faculty_id.attr('disabled', true);
    //   faculty_id.trigger("liszt:updated");
    //   clear_form_error( faculty_id.closest('.field_error') );
      
    // });

    // если в поле "универ" произошли изменения
    $('.university-field .university_id').on('change', function(){

      // обновим список факультетов в  селекте
      faculty_field($('.faculty-field'));
      clear_form_error( $('.faculty-field').closest('.field_error') );
    });
  }

  if($('.ed-platform-field').length){
    ed_platform_field($('.ed-platform-field'));

    initChosen();

    initSkills();
  }

  (function(container) {
    if (!container.length) return;

    educationCourseField(container);
  })($('.ed-course-field'));
});


function college_field ($college_field){
  var $college_input    = $college_field.find('input.college');
  var $college_id_input = $college_field.find('input.college_id');
  var last_value='';
  
  $college_input.autocomplete({
    autoFocus: true,
    minLength: 0,
    source: function(request, response) {
        var data = { term: request.term };
        // var city_id = $('.location-field .city_id').val();
        // if(city_id !== ''){
        //   data.city_id = city_id;
        // }
        $.get('/suggest/colleges', data, function(data) {
            response(data);
        });
    },
    select: function( event, ui ) {
      $college_input.val( ui.item.name );
      $college_id_input.val( ui.item.id );
      last_value = ui.item.name;
      $college_input.trigger('change');
      $college_id_input.trigger('change');
      return false;
    }
  }).on('keyup', function(e){
    if( last_value !== $(this).val()){
      $college_id_input.val( '' );
      $college_input.trigger('change');
      $college_id_input.trigger('change');
    }
  }).on('blur', function(){
      if( $college_id_input.val() === '' ) {
        $college_input.val( '' );
        $college_input.trigger('change');
        $college_id_input.trigger('change');
      }
    })
  .data( "ui-autocomplete" )._renderItem = function( ul, item ) {
      return $( "<li>" )
        .addClass('college_li')
        .append( $( "<a>" ).text(item.name) )
        .appendTo( ul );
  };
  $college_input.autocomplete( "widget" ).addClass( "college_select" );
}

function faculty_field($field){
  var select = $field.find('.faculty_id');
  var university_id = $('.university-field .university_id').val();
  if(university_id === ''){
    select.html('');
    select.attr('disabled', true);
    select.trigger("liszt:updated");
  }else{
    $.get("/suggest/universities/"+university_id+"/faculties", function(faculties){
      var options = [];
      for(var k in faculties){
        var faculty = faculties[k];
        options.push($('<option value="'+faculty.id+'"></option>').text(faculty.name));
      }
      select.html(options);
      select.attr('disabled', false);
      select.trigger("liszt:updated");
    });
  }
}

