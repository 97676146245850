/* prettier-ignore */
/* eslint-disable */

// Все экспорты в этом файле попадут в window.helpers
// Здесь только рельсовое легаси, новые методы добавлять не надо
// Вместо этого нужно создавать новые компоненты в src/components

import 'jquery-ui/ui/widgets/autocomplete';
import 'jquery-ui/ui/widgets/sortable';
import { notify as _notify } from 'src/notify.js';
import { fadeOut } from 'src/utils/animation.js';

export { debounce } from 'src/utils/debounce';

export function initPhoneMasks() {
  const allPhoneInputs = $('input.js-mask-phone:not(.js-mask-phone-inited)');
  const russianPhoneRegexp = /^([78])(\d{10})$/;

  function onlyDigitsValue(phoneString = '') {
    if (typeof phoneString !== 'string') return false;

    return phoneString.replace(/\D/g, '');
  }

  function isRussianMobilePhone(phoneString = '') {
    if (typeof phoneString !== 'string') return false;

    return russianPhoneRegexp.test(onlyDigitsValue(phoneString));
  }

  allPhoneInputs.each((_, inputElement) => {
    const phoneInput = $(inputElement);
    let lastValue = phoneInput.val();

    const masks = ['+00000000000000000', '+7 (000) 000-00-00'];
    const [commonMask, rusMobileMask] = masks;

    const maskPluginOptions = {
      onKeyPress: function (newValue, e, field, options) {
        const isRusMobile = newValue.startsWith('+7');
        const isRusShort =
          newValue.startsWith('+8') && (lastValue === '' || lastValue === '8');

        if (isRusShort) {
          $(field).val('+7');
          setCaretPosition(field[0], 2);
          return;
        }

        const dynamicMask = isRusMobile ? rusMobileMask : commonMask;

        $(field).mask(dynamicMask, maskPluginOptions);
      },
    };

    phoneInput.on('input', (event) => {
      lastValue = event.target.value;
    });

    phoneInput.on('paste', (event) => {
      event.preventDefault();
      const pastedValue = event.originalEvent.clipboardData.getData('text');

      const newValue = isRussianMobilePhone(pastedValue)
        ? onlyDigitsValue(pastedValue).replace(russianPhoneRegexp, '+7$2')
        : onlyDigitsValue(pastedValue);

      const newMask = isRussianMobilePhone(pastedValue)
        ? rusMobileMask
        : commonMask;

      phoneInput.unmask();
      phoneInput.val(newValue);
      phoneInput.mask(newMask, maskPluginOptions);
      lastValue = newValue;
    });

    import('src/libs/jquery.mask.js').then(() => {
      const initialMask = isRussianMobilePhone(onlyDigitsValue(lastValue))
        ? rusMobileMask
        : commonMask;
      if (lastValue.startsWith('8')) {
        lastValue = lastValue.replace('8', '+7');
        phoneInput.val(lastValue);
      }
      phoneInput.mask(initialMask, maskPluginOptions);
      phoneInput.addClass('js-mask-phone-inited');
    });
  });
}

export function stickyButtonsInit() {
  const buttons = $(this);

  const fixedButtons = buttons.clone();
  buttons.after(fixedButtons);
  fixedButtons.addClass('fixed');
  $(window).on('scroll resize', checkPositions);
  $(window).on('stickyButtonsCheckPositions', checkPositions);

  checkPositions();

  function checkPositions() {
    fixedButtons.css('width', buttons.outerWidth());
    const buttonsBottom = buttons.offset().top + buttons.outerHeight();
    // для мобильной клавиатуры лучше работает $(window).height()
    // для обычного скролла экрана, без наслоения кнопок отрабатывает window.visualViewport.height
    // поэтому осталось оба условия
    const windowBottomViewport = window.scrollY + window.visualViewport.height;
    const windowBottom = window.scrollY + $(window).height();
    if (buttonsBottom > windowBottom && buttonsBottom > windowBottomViewport) {
      fixedButtons.addClass('show');
    } else {
      fixedButtons.removeClass('show');
    }
  }
}

export function clear_form_errors() {
  $('.field_error').each(function (index, field_error) {
    clear_form_error(field_error);
  });
}

export function clear_form_error(field_error) {
  $('.validation-error', field_error).remove();
  $(field_error).next('.validation-error').remove();
  $(field_error).removeClass('field_error');
}

export function scroll_to_first_error() {
  $(window).scrollTo(
    $('.field_error:not(.hidden):not(:empty)').closest('.field'),
    10,
    { axis: 'y', offset: -20 },
  );
}

export function customize_select(select) {
  select.each((i, el) => {
    const $select = $(el);

    $select
      .chosen({
        disable_search: true,
        disable_search_threshold: 10,
        width: $select.attr('data-width'),
      })
      .change(function (e, value) {
        const input = $(this).closest('.input');
        input.find('.field_error').removeClass('field_error');
        input.find('.validation-error').remove();
      });

    // чтобы сообщение об ошибке показывалось под селектом, а не над ним
    if (el.classList.contains('js-displays-error')) {
      $select.insertAfter($select.next());
    }
  });
}

export function customize_select_wsearch(select) {
  select
    .chosen({
      disable_search: false,
      width: '100%',
    })
    .change(function (e, value) {
      const input = $(this).closest('.input');
      input.find('.field_error').removeClass('field_error');
      input.find('.validation-error').remove();
    });
}

export function test_debug_function() {
  return 0;
}

export function location_field_customize($location_field) {
  const $location_input = $location_field.find('input.location');
  const $city_id_input = $location_field.find('input.city_id');

  let last_value = '';
  if ($location_input.val().length > 0) {
    last_value = $location_input.val();
  }
  $location_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: '/suggest/cities',
      select: function (event, ui) {
        // if( typeof(ui.item.type) !== 'undefined' &&  ui.item.type === 'not_found' ){
        //   $location_input.val( '' );
        //   $city_id_input.val( '' );
        //   last_value = '';
        // }else{
        $location_input.val(ui.item.name);
        $city_id_input.val(ui.item.id);
        last_value = ui.item.name;
        //}
        $location_input.trigger('change');
        $city_id_input.trigger('change');
        return false;
      },
      appendTo: $location_field,
    })
    .on('focus', function (e) {
      if ($location_input.data('autoload')) {
        $location_input.autocomplete('search');
      }
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $city_id_input.val('');
        if ($city_id_input.val().length > 0) {
          $location_input.trigger('change');
          $city_id_input.trigger('change');
        }
      }
    })
    .on('blur', function () {
      if ($city_id_input.val() === '') {
        $location_input.val('');
        $location_input.trigger('change');
        $city_id_input.trigger('change');
      }
    })
    .data('ui-autocomplete')._renderItem = function (ul, item) {
    const html = $('<a>')
      .text(item.name)
      .append(
        $("<span class='location'>").text(
          item.country_name + ', ' + item.region_name,
        ),
      );
    return $('<li>').addClass('location_li').append(html).appendTo(ul);
  };
  $location_input.autocomplete('widget').addClass('location_select');
}

export function setPopupPosition(popup) {
  let margin_top;
  const popup_inner = popup.find('.popup_inner');
  const popup_height = popup_inner.outerHeight();
  const window_height = $(window).height();
  const is_mobile =
    typeof $('body').attr('data-responsive') !== 'undefined' &&
    $(window).width() <= 1023;

  if (is_mobile) {
    popup_inner.css('marginTop', '0px');
    return;
  }

  if (popup_height < window_height - 50) {
    margin_top = (window_height - popup_height) / 2;
  } else {
    const header_height = popup.find('.popup_header').outerHeight();
    const footer_height = popup.find('.popup_footer').outerHeight();

    const new_height = window_height - header_height - footer_height - 100;
    margin_top = 50;
    $(popup_inner)
      .find('.popup_body')
      .css('height', new_height + 'px');
    $(popup_inner)
      .find('.popup_body .category_selector')
      .css('height', new_height + 'px');
  }

  popup_inner.css('marginTop', margin_top + 'px');
}

function fill_target($category_selector) {
  const target = $category_selector.data('target');
  if (!target) {
    return false;
  }
  let value = $category_selector
    .find('.subcategories input:checked')
    .data('value');
  if (value && !$category_selector.hasClass('courses')) {
    $(target).val(value);
    $(target).trigger('change');
  } else if ($category_selector.hasClass('salaries')) {
    value = $category_selector.find('input[name="sg"]:checked').data('value');
    if (value) {
      $(target).val(value);
      $(target).trigger('change');
    }
  } else if ($category_selector.hasClass('courses')) {
    const group = $category_selector
      .find('input[name="education_course[specialization_group_id]"]:checked')
      .data('value');
    const category = $category_selector
      .find('input[name="education_course[specialization_id]"]:checked')
      .data('value');
    value = group;
    if (category) {
      value += ' / ' + category;
    }
    if (value) {
      $(target).val(value);
      $(target).trigger('change');
    }
  }
}

export function init_category_selector($category_selector) {
  function disable_unchecked_subcategories($category_selector) {
    $category_selector
      .find('.subcategories input[type="checkbox"]')
      .each(function () {
        const checkbox = $(this);
        if (!checkbox.is(':checked')) {
          checkbox.attr('disabled', true);
        }
      });
  }

  function enable_unchecked_subcategories($category_selector) {
    $category_selector
      .find('.subcategories input[type="checkbox"]:disabled')
      .attr('disabled', false);
  }

  function calc_selected_subcategories($category_selector) {
    $category_selector.find('.category_item').each(function () {
      const category_item = $(this);
      const checked_count = category_item.find('.subcategories input:checked')
        .length;
      const count = checked_count > 0 ? ' (' + checked_count + ')' : '';
      category_item.find('.name .count').text(count);
      if (checked_count > 0) {
        category_item.addClass('activated');
      } else {
        category_item.removeClass('activated');
      }
    });
  }

  function toggle_disabled_checkboxes($category_selector) {
    const max_items = parseInt($category_selector.data('max_items'));
    const checked_count = $category_selector.find(
      '.subcategories input:checked',
    ).length;
    if (checked_count >= max_items) {
      disable_unchecked_subcategories($category_selector);
    } else {
      enable_unchecked_subcategories($category_selector);
    }
  }

  function calc_total_selected_subcategories($category_selector) {
    const max_items = parseInt($category_selector.data('max_items'));
    const checked_count = $category_selector.find(
      '.subcategories input:checked',
    ).length;
    const text =
      checked_count > 0
        ? 'Выбрано ' + checked_count + ' из ' + max_items + ''
        : ' ';
    if ($category_selector.hasClass('form')) {
      const button = $category_selector
        .closest('.popup_category_selector')
        .find('.button');
      if (checked_count > 0) {
        button.attr('disabled', false);
      } else {
        button.attr('disabled', 'disabled');
      }
    }
    $category_selector
      .closest('.field')
      .find('.category_selector_input')
      .text(text);
  }

  function show_selected_categories($category_selector) {
    function get_selected_categories_names($category_selector) {
      const result = {};
      $category_selector.find('.category_item').each(function () {
        const category_item = $(this);
        const category_name =
          category_item.find('.name .category_name').text() || 'default';
        category_item.find('.subcategories input:checked').each(function () {
          const subcategory = $(this).closest('.checkbox_single');
          const subcategory_name = subcategory.find('.subcategory_name').text();
          if (typeof result[category_name] === 'undefined') {
            result[category_name] = [];
          }
          result[category_name].push(subcategory_name);
        });
      });
      return result;
    }

    const categories = get_selected_categories_names($category_selector);

    const html = [];
    $.each(categories, function (category_name, subcategories) {
      if (category_name !== 'default') {
        html.push($('<div class="category_name"></div>').text(category_name));
      }
      $.each(subcategories, function (index, subcategory_name) {
        html.push(
          $('<div class="subcategory_name"></div>').text(
            '— ' + subcategory_name,
          ),
        );
      });
    });
    $category_selector
      .closest('.field')
      .find('.selected_categories_names')
      .html(html);
  }

  calc_selected_subcategories($category_selector);
  if (!$category_selector.hasClass('salaries')) {
    toggle_disabled_checkboxes($category_selector);
  }

  calc_selected_subcategories($category_selector);
  calc_total_selected_subcategories($category_selector);

  show_selected_categories($category_selector);

  $category_selector.find('input').on('change', function () {
    calc_selected_subcategories($category_selector);
    calc_total_selected_subcategories($category_selector);
    show_selected_categories($category_selector);
    const field = $category_selector.closest('.field');
    if (field.find('.field_error').length > 0) {
      field.find('.field_error').removeClass('field_error');
      field.find('.validation-error').remove();
    }
    if (
      $category_selector.hasClass('courses_filter') &&
      !$(this).hasClass('group')
    ) {
      const sid = $(this).data('id');
      const root = $(this).closest('.field').find('.selected_options');
      if ($(this).prop('checked')) {
        const value = $(this).data('value');
        root.append(
          '<div class="option" id="sid_' +
            sid +
            '">' +
            value +
            '<span class="remove"></span><input type="hidden" name="s[]" id="s_" value="' +
            sid +
            '"></div>',
        );
      } else {
        root.find('.option#sid_' + sid).remove();
      }
    }
    fill_target($category_selector);
  });

  const hasSalaries = $category_selector.hasClass('salaries');
  const hasCourses = $category_selector.hasClass('courses');

  if (hasSalaries || hasCourses) {
    $category_selector.find('input[type="checkbox"]').on('change', function () {
      const state = $(this).prop('checked');
      $category_selector.find('input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', state);
      calc_selected_subcategories($category_selector);
      fill_target($category_selector);
    });
  }

  if (hasSalaries) {
    $category_selector.find('input[name="sg"]').on('change', function () {
      $category_selector.find('input[type="checkbox"]').prop('checked', false);
      calc_selected_subcategories($category_selector);
      fill_target($category_selector);
    });
  } else if (hasCourses) {
    $category_selector
      .find('input[name="education_course[specialization_group_id]"]')
      .on('change', function () {
        $category_selector
          .find('input[name="education_course[specialization_id]"]')
          .prop('checked', false);
        calc_selected_subcategories($category_selector);
        $(this).closest('.category_item').addClass('activated');
        fill_target($category_selector);
      });
  } else {
    $category_selector.find('input[type="checkbox"]').on('change', function () {
      toggle_disabled_checkboxes($category_selector);
    });
  }
}

export function company_field($company_field) {
  function toggle_experience_form_controls(enable_klasses, disable_klasses) {
    let form = $company_field.closest('form');
    enable_klasses.forEach(function (enable_klass) {
      form.find('.field.' + enable_klass).removeClass('hidden');
      form.find('.field.' + enable_klass + ' input').removeAttr('disabled');
    });

    disable_klasses.forEach(function (disable_klass) {
      form.find('.field.' + disable_klass).addClass('hidden');
      form
        .find('.field.' + disable_klass + ' input')
        .attr('disabled', 'disabled');
    });
  }

  const $company_name_input = $company_field.find('input.company_name');
  const $company_id_input = $company_field.find('input.company_id');
  const $full_width = $company_field.hasClass('full_width');

  let last_value = '';
  if ($company_name_input.val().length > 0) {
    last_value = $company_name_input.val();
  }
  let onboard_control = false;
  if ($company_field.hasClass('onboard_experience')) {
    onboard_control = true;
  }
  $company_name_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: '/suggest/companies',
      select: function (event, ui) {
        $company_name_input.val(ui.item.name);
        $company_id_input.val(ui.item.id);
        last_value = ui.item.name;
        $company_name_input.trigger('change');
        if (onboard_control) {
          toggle_experience_form_controls(
            ['existing_company'],
            ['new_company'],
          );
        }
        return false;
      },
    })
    .on('focus', function (e) {
      if ($company_name_input.data('autoload')) {
        $company_name_input.autocomplete('search');
      }
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $company_id_input.val('');
        if (onboard_control && $(this).val().length > 0) {
          toggle_experience_form_controls(
            ['new_company'],
            ['existing_company'],
          );
        }
      }
      if (onboard_control && $(this).val().length < 1) {
        toggle_experience_form_controls(
          [],
          ['new_company', 'existing_company'],
        );
      }
    })
    .data('ui-autocomplete')._renderItem = function (ul, item) {
    const html = $('<a>')
      .append('<span>' + item.name + '</span>')
      .prepend($('<img>').attr('src', item.logo_url));
    if (item.city) {
      html
        .find('span')
        .append("<span class='location'>" + item.city + '</span>');
    }
    if ($full_width) {
      html.find('span').addClass('full_width');
    }
    return $('<li>').addClass('company_li').append(html).appendTo(ul);
  };
  $company_name_input.autocomplete('widget').addClass('company_select');
}

export function initSkills() {
  setMaxNested(10, $('#add_skills'), $('#ihave_skills'));

  $('.skill-field').each(function (index, field) {
    skill_field_customize($(field));
  });

  $('#ihave_skills').on('nested:fieldAdded', function (event) {
    $('.skills-selector .validation-error').remove();
    const field = event.field;

    field.find('.skill-field').each(function (index, field) {
      skill_field_customize($(field));
      $(field).find('input[name="skill"]').focus();
    });
  });

  loadRecomendedSkills();

  // перестроим список рекомендованных, когда сферы деятельности изменятся
  $(document).on('change', '.division_list input', loadRecomendedSkills);

  // сделаем nested элементы сортируемыми
  $('#ihave_skills').sortable({
    items: '.fields',
    handle: '.move_nested_field',
    opacity: 0.5,
    placeholder: 'ui-state-highlight',
  });
}

export function initVacancyCities() {
  setMaxNested(3, $('#add_cities'), $('#ihave_cities'));

  $('.location-field').each(function (index, field) {
    location_field_customize($(field));
  });

  $('#ihave_cities').on('nested:fieldAdded', function (event) {
    const field = event.field;

    field.find('.location-field').each(function (index, field) {
      location_field_customize($(field));
      $(field).find('input[name="location"]').focus();
    });
  });
}

export function setMaxNested(maxFieldsCount, $addLink, $container) {
  let fieldsCount;

  function toggleAddLink() {
    const show = fieldsCount < maxFieldsCount;
    $addLink.toggle(show);
    if (show) {
      $('.recomended_skills').removeClass('disabled');
    } else {
      $('.recomended_skills').addClass('disabled');
    }
  }

  $container.on('nested:fieldAdded', function () {
    fieldsCount += 1;
    toggleAddLink();
    loadRecomendedSkills();
    initPhoneMasks();
  });

  $container.on('nested:fieldRemoved', function () {
    fieldsCount -= 1;
    toggleAddLink();
    loadRecomendedSkills();
  });

  // count existing nested fields after page was loaded
  fieldsCount = $container.find('.fields').length;
  toggleAddLink();
}

export function skill_field_customize($field) {
  const $skill_input = $field.find('input.skill');
  const $skill_id_input = $field.find('input.skill_id');

  let last_value = '';
  if ($skill_input.val().length > 0) {
    last_value = $skill_input.val();
  }

  $skill_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: '/suggest/skills',
      select: function (event, ui) {
        $skill_input.val(ui.item.title);
        $skill_id_input.val(ui.item.id);
        last_value = ui.item.title;
        loadRecomendedSkills();
        $skill_input.trigger('change');
        $('.skills-selector .validation-error').remove();
        $skill_input
          .closest('.field')
          .find('.controls input[type="hidden"]')
          .val('false');
        return false;
      },
    })
    .on('focus', function (e) {
      if ($skill_input.data('autoload')) {
        $skill_input.autocomplete('search');
      }
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $skill_id_input.val('');
        $skill_input
          .closest('.field')
          .find('.controls input[type="hidden"]')
          .val('true');
      } else {
        $skill_input
          .closest('.field')
          .find('.controls input[type="hidden"]')
          .val('false');
      }
    })
    .on('blur', function () {
      if ($skill_id_input.val() === '') {
        $skill_input.val('');
        $skill_input
          .closest('.field')
          .find('.controls input[type="hidden"]')
          .val('true');
        $('#ihave_skills').trigger('nested:fieldRemoved');
      }
    })
    .data('ui-autocomplete')._renderItem = function (ul, item) {
    return (
      $('<li>')
        // .addClass('skill_li')
        .append($('<a>').text(item.title))
        .appendTo(ul)
    );
  };
  $skill_input.autocomplete('widget'); //.addClass( "skill_select" );
}

export function loadRecomendedSkills() {
  function getDivisionIds() {
    const division_ids = $('#ihave_skills').attr('data-division_ids');
    if (division_ids) return JSON.parse(division_ids);
    return $('.division_list input:checked')
      .map(function () {
        return parseInt(this.value);
      })
      .toArray();
  }

  function getCurrentSkillsIds() {
    return $('#ihave_skills .fields:visible .skill_id')
      .filter(function () {
        return this.value !== '';
      })
      .map(function () {
        return parseInt(this.value);
      })
      .toArray();
  }

  // TODO: удалить после всеобщего перехода на новый компонент навыков
  function showRecomendedSkills(skills) {
    const currentSkillsIds = getCurrentSkillsIds();

    const skillsForShow = skills.filter(function (skill) {
      return currentSkillsIds.indexOf(skill.id) === -1;
    });

    if (skillsForShow.length === 0) {
      $('.recomended_skills').removeClass('hasSkills');
      return;
    }
    const recomended_skills = $('.recomended_skills .skills');
    const elements = skillsForShow.map(function createSkill(skill) {
      const element = $('<a href="#add" class="skill"></a>');
      element.text(skill.title);
      element.on('click', function () {
        let fields = $('#ihave_skills .fields').last();
        if (fields.find('.skill_id').val() !== '') $('#add_skills').click();
        fields = $('#ihave_skills .fields').last();
        fields.find('.skill_id').val(skill.id);
        // this sets skill title to input[text] field,
        // also we trigger `change` on it to fetch salary recommendations
        fields.find('.skill').val(skill.title).trigger('change');
        fields.find('.controls input[type="hidden"]').val('false');
        $('.skills-selector .validation-error').remove();
        loadRecomendedSkills();
        return false;
      });
      return element;
    });
    recomended_skills.html(elements);
    $('.recomended_skills').addClass('hasSkills');
  }

  const divisionIds = getDivisionIds();
  const currentSkillsIds = getCurrentSkillsIds();
  const url = '/suggest/skills/similar';
  const data = {};

  if (divisionIds.length) data['division_ids'] = divisionIds.join(',');
  if (currentSkillsIds.length) data['skill_ids'] = currentSkillsIds.join(',');

  $.get(url, data, showRecomendedSkills);
}

export function initSpecializations() {
  $(document).on('click', '.specialization_name', function () {
    const input = $(this);
    const field = input.closest('.field');
    const popup = field.find('.global_popup');
    popup.find('.close_popup_button').removeClass('disabled loading');
    if (!popup.find('.category_selector').hasClass('form')) {
      popup.find('.close_popup_button').prop('disabled', false);
    }
    const has_checked =
      popup.find('.category_selector input[name="sg"]:checked').length > 0;
    if (!has_checked) {
      $(popup.find('.category_selector input[name="sg"]')[0]).prop(
        'checked',
        true,
      );
      setTimeout(function () {
        fill_target(popup.find('.category_selector'));
      }, 100);
    }
    popup.addClass('opened');
    setPopupPosition(popup);
    $('body').addClass('noscroll');
    return false;
  });
}

export function updateCoursesSortLabel() {
  const checked = $('.js-courses-search-dropdown input:checked')
    .map(function (i, val) {
      return $(val).closest('.radio_single').find('label').text();
    })
    .toArray();
  const togglerText =
    checked.length > 0 ? 'Сортировать ' + checked.join(', ') : '';
  const toggler = $('.js-courses-search-dropdown .toggler');
  const icon = $('<span class="icon-Arrow"></span>');
  toggler.text(togglerText);
  toggler.append(icon);
}

export function educationCourseField($container) {
  const $courseNameInput = $container.find('input.education_course_name');
  const $courseIdInput = $container.find('input.education_course_id');
  const $courseType = $container.find('input.course_type');

  const educationPlatform = $('#additional_education_education_platform_id');

  let lastCourseName = '';

  const autocompleteOptions = {
    autoFocus: true,
    minLength: 0,
    source: function (request, responseCb) {
      const data = { term: request.term, center_id: educationPlatform.val() };

      if (!data.center_id) {
        responseCb([]);
        return;
      }

      $.getJSON('/suggest/education_courses', data, function (result) {
        responseCb(
          $.grep(result, function (item) {
            return !!item; // it will strip `null` values
          }),
        );
      });
    },

    select: function (event, ui) {
      lastCourseName = ui.item.title;

      $courseNameInput.val(ui.item.title).trigger('change');
      $courseIdInput.val(ui.item.id).trigger('change');

      $courseType.val(ui.item.type);

      return false;
    },
  };

  const focusInput = function () {
    if ($courseNameInput.data('autoload')) {
      $courseNameInput.autocomplete('search');
    }
  };

  const keyUp = function () {
    if (lastCourseName === $(this).val()) return;

    $courseIdInput.val('');
    $courseType.val('');
  };

  const renderItem = function (ul, item) {
    const html = ['<li class="company_li"><a>', item.title, '</a></li>'].join(
      '',
    );

    return $(html).appendTo(ul);
  };

  $courseNameInput.autocomplete(autocompleteOptions);
  $courseNameInput.on('focus', focusInput);
  $courseNameInput.on('keyup', keyUp);
  $courseNameInput.data('ui-autocomplete')._renderItem = renderItem;

  $courseNameInput.autocomplete('widget').addClass('education_course_select');
}

export function ed_platform_field($platform_field) {
  let $platform_name_input = $platform_field.find(
    'input.education_platform_name',
  );
  let $platform_id_input = $platform_field.find('input.education_platform_id');
  if ($platform_name_input.length < 1) {
    $platform_name_input = $platform_field.find('input#center_name');
    $platform_id_input = $platform_field.find('input#center_id');
  }
  const $hidden_field_wrapper = $platform_id_input
    .closest('.field')
    .data('target');

  const $courseNameInput = $platform_field
    .closest('.fields_group')
    .find('input.education_course_name');
  const $courseIdInput = $platform_field
    .closest('.fields_group')
    .find('input.education_course_id');
  const $externalCourseIdInput = $platform_field
    .closest('.fields_group')
    .find('input.external_education_course_id');

  let last_value = '';
  if ($platform_name_input.val().length > 0) {
    last_value = $platform_name_input.val();
  }
  $platform_name_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: '/suggest/education_platforms',
      select: function (event, ui) {
        $platform_name_input.val(ui.item.short_name);
        $platform_id_input.val(ui.item.id);
        last_value = ui.item.short_name;
        $platform_name_input.trigger('change');
        if ($hidden_field_wrapper.length > 0) {
          const wrapper = $platform_id_input
            .closest('form')
            .find($hidden_field_wrapper);
          if (ui.item.can_have_authors) {
            wrapper.removeClass('hidden');
            wrapper.find('input').prop('disabled', '');
          } else {
            wrapper.addClass('hidden');
            wrapper.find('input').prop('disabled', 'disabled');
          }
        }

        // reset educationCourse
        $courseNameInput.prop('disabled', false).val('');
        $courseIdInput.val('');
        $externalCourseIdInput.val('');

        return false;
      },
    })
    .on('focus', function (e) {
      if ($platform_name_input.data('autoload')) {
        $platform_name_input.autocomplete('search');
      }
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $platform_id_input.val('');
        if ($hidden_field_wrapper.length > 0) {
          const wrapper = $platform_id_input
            .closest('form')
            .find($hidden_field_wrapper);
          wrapper.addClass('hidden');
          wrapper.find('input').prop('disabled', 'disabled');
        }
      }
    })
    .on('blur', function () {
      const disabled = $(this).val().trim().length <= 0;
      $courseNameInput.prop('disabled', disabled);
    })
    .data('ui-autocomplete')._renderItem = prepareAutocomplete;
  $platform_name_input.autocomplete('widget').addClass('company_select');
}

function prepareAutocomplete(ul, item) {
  const html = $('<a>')
    .append('<span>' + (item.short_name || item.name) + '</span>')
    .prepend($('<img>').attr('src', item.logo_url));
  if (item.city) {
    html.find('span').append("<span class='location'>" + item.city + '</span>');
  }
  return $('<li>').addClass('company_li').append(html).appendTo(ul);
}

export function university_field($university_field) {
  const $university_input = $university_field.find('input.university');
  const $university_id_input = $university_field.find('input.university_id');
  let last_value = '';
  const $clear_on_change = !$university_field.hasClass('user_input');

  $university_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: function (request, response) {
        const data = { term: request.term };
        // var city_id = $('.location-field .city_id').val();
        // if(city_id !== ''){
        //   data.city_id = city_id;
        // }
        $.get('/suggest/universities', data, function (data) {
          response(data);
        });
      },
      select: function (event, ui) {
        $university_input.val(ui.item.display_name);
        $university_id_input.val(ui.item.id);
        last_value = ui.item.display_name;
        $university_input.trigger('change');
        $university_id_input.trigger('change');

        return false;
      },
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $university_id_input.val('');
        if ($clear_on_change) {
          $university_input.trigger('change');
          $university_id_input.trigger('change');
        }
      }
    })
    .on('blur', function () {
      if ($university_id_input.val() === '' && $clear_on_change) {
        $university_input.val('');
        $university_input.trigger('change');
        $university_id_input.trigger('change');
      }
    })
    .data('ui-autocomplete')._renderItem = function (ul, item) {
    let name, display_name;
    if (item.display_name) {
      display_name = $('<span class="display_name"></span>').text(
        item.display_name,
      );
      name = $('<span class="name"></span>').text(item.name);
    } else {
      name = $('<span ></span>').text(item.name);
    }

    return $('<li>')
      .addClass('university_li')
      .append($('<a>').html(display_name).append(name))
      .appendTo(ul);
  };
  $university_input.autocomplete('widget').addClass('university_select');
}

export function updateCompaniesListUrl() {
  const formData = $('.js-companies-search-filter-form').serializeArray();

  const params = $.map(formData, function (field, i) {
    if (field.value !== '' && field.name !== 'utf8') {
      return field;
    }
  });
  const url = $.param(params);
  const root = $('.js-companies-search-filter-form').data('root');

  if (history.replaceState) {
    history.replaceState({}, '', '/' + root + '?' + url);
  }
}

export function updateCompaniesSortLabel() {
  const checked = $('.js-companies-search-dropdown input:checked')
    .map(function (i, val) {
      return $(val).closest('.radio_single').find('label').text();
    })
    .toArray();
  const togglerText =
    checked.length > 0 ? 'Сортировать ' + checked.join(', ') : '';
  const toggler = $('.js-companies-search-dropdown .toggler');
  const icon = $('<span class="icon-Arrow"></span>');
  toggler.text(togglerText);
  toggler.append(icon);
}

export function setRatingsLoaderTimer() {
  if (!$('.user_ratings_loader').length) return;
  const from_onboard = $('.user_ratings_loader').data('onboard');
  const habr = $('.user_ratings_loader').data('habr');
  const onboard = from_onboard && from_onboard.length > 0;
  setTimeout(function () {
    $.ajax({
      url: '/preferences/socials/load',
      data: { onboard: onboard, habr: habr },
      datatype: 'script',
    });
  }, 10000);
}

export function updateUniversitiesListUrl() {
  const formData = $('.js-universities-search-filter-form').serializeArray();

  const params = $.map(formData, function (field, i) {
    if (field.value !== '' && field.name !== 'utf8') {
      return field;
    }
  });
  const url = $.param(params);
  const root = $('.js-universities-search-filter-form').data('root');

  if (history.replaceState) {
    history.replaceState({}, '', '/' + root + '?' + url);
  }
}

export function setResumesSearchUrl() {
  const formData = $('.js-resumes-search-filter-form').serializeArray();

  const params = $.map(formData, function (field, i) {
    if (field.value !== '' && field.name !== 'utf8') {
      return field;
    }
  });
  const url = $.param(params);
  let base_url = '/resumes?';
  const overriden_base_url = $('.js-resumes-search-filter-form').data(
    'base-url',
  );
  if (overriden_base_url) {
    base_url = overriden_base_url;
  }

  if (history.replaceState) {
    history.replaceState({}, '', base_url + url);
  }
}

export function initMultiselect() {
  $('.js-multiselect').each(function () {
    const $multiselect = $(this);
    const $list = $multiselect.find('.selected_options');
    const $input = $multiselect.find('input.text');
    const sourceUrl = $multiselect.attr('data-suggest-url');
    const fieldName = $multiselect.attr('data-field-name');
    const propName = $multiselect.attr('data-prop-name');
    const sourceObj = $multiselect.attr('data-source');

    if ($list.length === 0) return false;

    $input
      .autocomplete({
        minLength: 0,
        source: sourceUrl,
        select: function (event, ui) {
          const $item = $('<div>');
          const $remove = $('<span>');
          const $hiddenField = $('<input>');
          let field_name = fieldName;
          if (ui.item.field_name) {
            field_name = ui.item.field_name;
          }

          let alreadyAdded = false;
          $('input[name="' + field_name + '"]').each(function (i, input) {
            if ($(input).val() == ui.item.id.toString()) {
              alreadyAdded = true;
            }
          });
          if (alreadyAdded) {
            $input.val('');
            return false;
          }

          $remove.addClass('remove');

          $hiddenField
            .attr('type', 'hidden')
            .attr('name', field_name)
            .val(ui.item.id);

          $item
            .addClass('option')
            .text(ui.item[propName])
            .append($remove)
            .append($hiddenField);

          $list.append($item);

          $input.trigger('change').val('');

          return false;
        },
      })
      .on('focus', function (e) {
        if ($input.data('autoload')) {
          $input.autocomplete('search');
        }
      });

    if (sourceObj === 'city') {
      $input.data('ui-autocomplete')._renderItem = function (ul, item) {
        const html = $('<a>')
          .text(item.name)
          .append(
            $("<span class='location'>").text(
              item.country_name + ', ' + item.region_name,
            ),
          );
        return $('<li>').addClass('location_li').append(html).appendTo(ul);
      };
    } else if (
      sourceObj === 'company' ||
      sourceObj === 'hub' ||
      sourceObj === 'tag'
    ) {
      $input.data('ui-autocomplete')._renderItem = prepareAutocomplete;
    } else if (sourceObj === 'university') {
      $input.data('ui-autocomplete')._renderItem = function (ul, item) {
        const html = $('<a>').append('<span>' + item.display_name + '</span>');
        html
          .find('span')
          .append("<span class='location'>" + item.name + '</span>');
        return $('<li>').addClass('company_li').append(html).appendTo(ul);
      };
    } else {
      $input.data('ui-autocomplete')._renderItem = function (ul, item) {
        return $('<li>').append($('<a>').text(item[propName])).appendTo(ul);
      };
    }

    $input.autocomplete('widget');
  });
}

let redactorLoaded = false;
let redactorRequest;

export function initRedactor() {
  return new Promise((resolve) => {
    if (redactorLoaded) return resolve();
    redactorRequest = redactorRequest || import('src/libs/redactor');
    return resolve(redactorRequest);
  });
}

export function init_wysiwyg($textarea, callbacks = {}) {
  initRedactor().then(() => {
    $textarea.redactor({
      buttons: ['html', 'bold', 'italic', 'ul', 'ol', 'link'],
      allowedTags: [
        'p',
        'ul',
        'li',
        'ol',
        'b',
        'strong',
        'em',
        'i',
        'del',
        'em',
        'a',
      ],
      tabindex: 2,
      tabFocus: false,
      autoresize: false,
      cleanSpaces: false,
      pasteImages: false,
      autoparseLinks: false,
      autoparseImages: false,
      autoparseVideo: false,
      clipboardUpload: false,
      removeIframe: true,
      minHeight: '106px',
      lang: 'ru',
      callbacks: {
        keyup: function (e) {
          clear_form_error(
            $(this.source.getElement().nodes[0]).closest('.field_error'),
          );
        },
        ...callbacks,
      },
    });
    return $textarea;
  });
}

export function show_empty_block_in_vacancies_list() {
  if (
    $('.cp_vacancies_list').length &&
    $('.cp_vacancies_list .cp_vacancy').length === 0
  ) {
    $('#vacancies_table_header').addClass('hidden');
    $('#empty_block').removeClass('hidden');
  }
}

export function show_empty_block_in_carts_list() {
  if (
    $('.cp_cart_list').length &&
    $('.cp_cart_list table tr.cp_cart_item').length === 0
  ) {
    $('.cp_cart_list').remove();
    $('#empty_block').removeClass('hidden');
  }
}

export function setArsenalSearchUrl() {
  var formData = $('.js-arsenal-search-filter-form').serializeArray(),
    submitUrl = $('.js-arsenal-search-filter-form').data('url');

  var params = $.map(formData, function (field, i) {
    if (field.value !== '' && field.name !== 'utf8') {
      return field;
    }
  });
  var url = $.param(params);
  if (history.replaceState) {
    history.replaceState({}, '', submitUrl + '?' + url);
  }
}

export function graphCaptionPluralize(number, variants) {
  const cases = [2, 0, 1, 1, 1, 2];
  number = Math.abs(number);
  return variants[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

export function checkHelpUrlHash(hash, fallback, scroll) {
  if (hash) $(hash).toggleClass('open');
  else $(fallback).toggleClass('open');
  if (hash && scroll) {
    $(window).scrollTo($(hash), 10, { axis: 'y', offset: -20 });
  }
}

export function notify(message, type, delay) {
  const params = { message, type };
  if (delay) {
    params.delay = delay;
  }
  _notify(params);
}

export function setCaretPosition(input, position) {
  input.focus();
  input.setSelectionRange(position, position);
}

export const fade_out = fadeOut;

export function country_field_customize($country_field) {
  const $country_name_input = $country_field.find('input.country_name');
  const $country_id_input = $country_field.find('input.country');

  let last_value = '';
  if ($country_name_input.val().length > 0) {
    last_value = $country_name_input.val();
  }
  $country_name_input
    .autocomplete({
      autoFocus: true,
      minLength: 0,
      source: '/api/frontend/suggestions/countries',
      select: function (event, ui) {
        $country_name_input.val(ui.item.title);
        $country_id_input.val(ui.item.value);
        if (ui.item.eaeu) {
          $country_id_input.addClass('eaeu');
        } else {
          $country_id_input.removeClass('eaeu');
        }
        if (ui.item.eu) {
          $country_id_input.addClass('eu');
        } else {
          $country_id_input.removeClass('eu');
        }
        if (ui.item.yookassa_params.rur_enabled) {
          $country_id_input.addClass('yookassa_enabled');
        } else {
          $country_id_input.removeClass('yookassa_enabled');
        }
        if (ui.item.yookassa_params.rur_disabled) {
          $country_id_input.addClass('yookassa_disabled');
        } else {
          $country_id_input.removeClass('yookassa_disabled');
        }
        last_value = ui.item.title;
        //}
        $country_name_input.trigger('change');
        $country_id_input.trigger('change');
        return false;
      },
    })
    .on('focus', function (e) {
      if ($country_name_input.data('autoload')) {
        $country_name_input.autocomplete('search');
      }
    })
    .on('keyup', function (e) {
      if (last_value !== $(this).val()) {
        $country_id_input.val('');
        if ($country_id_input.val().length > 0) {
          $country_name_input.trigger('change');
          $country_id_input.trigger('change');
        }
      }
    })
    .on('blur', function () {
      if ($country_id_input.val() === '') {
        $country_name_input.val('');
        $country_id_input.removeClass('eaeu');
        $country_id_input.removeClass('eu');
        $country_name_input.trigger('change');
        $country_id_input.trigger('change');
      }
    })
    .data('ui-autocomplete')._renderItem = function (ul, item) {
    const html = $('<a>')
      .append("<span class='full_width'>" + item.title + '</span>')
      .prepend($('<img>').attr('src', item.icon));
    return $('<li>').addClass('company_li').append(html).appendTo(ul);
  };
  $country_name_input.autocomplete('widget').addClass('company_select');
}

export function trackEvent(
  eventCategory,
  eventAction,
  eventLabel,
  labelAsParam = false,
) {
  let eventName = `${eventCategory}_${eventAction}`;

  if (eventLabel && !labelAsParam) {
    eventName += `_${eventLabel}`;
  }

  if (labelAsParam) {
    window.gtag('event', eventName, {
      event_label: eventLabel,
    });
    return;
  }

  window.gtag('event', eventName);
}

export function trackEventByName(eventName, params = {}) {
  window.gtag('event', eventName, params);
}

export function init_fields_with_counter() {
  // counter в полях с maxlength
  $(
    '.maxlength_counter textarea[maxlength], .maxlength_counter input[maxlength]',
  ).each(function () {
    const input = $(this);
    const input_wrapper = $(this).parents('.input');
    input_wrapper.addClass('hasCounter');
    const length = input.val().length;
    const maxlength = parseInt(input.attr('maxlength'));
    const counter = $(
      '<span class="maxlength_counter">' + (maxlength - length) + '</span>',
    );

    input.after(counter);
    input.on('keyup change', function () {
      const length = input.val().length;
      counter.text(maxlength - length);
      if (maxlength <= length) {
        counter.addClass('limit');
      } else {
        counter.removeClass('limit');
      }
    });
  });
}
