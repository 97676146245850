$(function() {
  $('body').on('click', '.js-delivery_button', function() {
    if ($(this).hasClass('active')) {
      return false;
    }
    var type = $(this).data('type');
    var form = $(this).closest('.js-delivery-form');
    form.find('#delivery_delivery_type').val(type);
    form.find('.js-delivery_button').removeClass('active');
    form.find('.js-delivery_button').text('Выбрать');
    $(this).addClass('active');
    $(this).text('Выбрано');
  })
});
