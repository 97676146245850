import { initSpecializations } from "src/global-helpers.js";

$(function(){
  if(!$('.js-company_rating_form').length) return;

  $(document).on('click', '.score_mark', function(){
    var item = $(this);
    if (item.hasClass('active')) { return false; }

    var wrapper = item.closest('.score_input');

    wrapper.find('.score_mark').removeClass('active');
    item.addClass('active');
    wrapper.closest('.input').find('input.value').val(item.data('value'));
    item.closest('.field').removeClass('field_error').find('.validation-error').remove();
  });

  $(document).on('change', '#user_company_rating_company_id', function(){
    var target = $("#user_company_rating_company_id option:selected").data('company');
    var url = $("#user_company_rating_company_id").data('url');
    $.ajax({
      url: url,
      data: {company: target},
      method: 'GET',
      dataType: 'script'
    });
  });

  $('.specialization_name').on('blur', function(){
    if( $(this).val() === '' ) {
      $('input[name="s"]').prop('checked', false);
    }
  });

  initSpecializations();
});
