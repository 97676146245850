/* global $, pluralize */

function pluralize(i, str0, str1, str2, str3) {
  if (!i) return parse(str0, i);
  switch (plural(i)) {
    case 0:
      return parse(str1, i);
    case 1:
      return parse(str2, i);
    default:
      return parse(str3, i)
  }
}

function parse(str) {
  var args = [].slice.call(arguments, 1);
  var i = 0;

  return str.replace(/%d/g, function() {
    return args[i++]
  })
}

function plural(a) {
  if (a % 10 === 1 && a % 100 !== 11) {
    return 0
  } else if (a % 10 >= 2 && a % 10 <= 4 && (a % 100 < 10 || a % 100 >= 20)) {
    return 1
  } else {
    return 2
  }
}

let suggestCompaniesRequest;
$(document).on(
  'keyup change input',
  '#new_company_name',
  window.helpers.debounce(function () {
    const input = $(this);
    const term = input.val();

    if (suggestCompaniesRequest) {
      suggestCompaniesRequest.abort();
      suggestCompaniesRequest = null;
    }

    suggestCompaniesRequest = $.getJSON('/suggest/companies', {
      term,
    }).done(renderLimitMessage.bind(this, term));
  }, 500),
);

function renderLimitMessage(term, json) {
  var count = json.length;
  var text = '';
  if (count && term.trim() !== '') {
    var conutStr = count > 20 ? 'более 20' : pluralize(count, '0 компаний', '%d компанию', '%d компании', '%d компаний');
    var companiesLink = '<a href="/companies?q=' + term + '" target="_blank">' + conutStr + ' с похожим названием</a>';
    text = 'Мы нашли ' + companiesLink + '. Проверьте, может среди них уже есть ваша. Тогда <a href="/feedback" target="_blank">напишите нам просьбу</a> сделать вас её куратором.'
  }
  $('.limitMessage').html(text)
}
