<template>
  <a
    :href="href"
    :target="target"
    class="link-comp"
    :class="bem('link-comp', { appearance, underline, fullWidth, layout })"
    :rel="linkRel"
  ><template v-if="title">{{title}}</template><slot /></a>
</template>

<script>
  import { bem } from "src/utils/bem.js";

  export default {
    name: 'BasicLink',
    props: {
      href: {
        type: String,
        required: true,
      },
      target: String,
      title: String,
      appearance: String,
      underline: String,
      fullWidth: Boolean,
      layout: String,
      rel: String,
    },
    methods: {
      bem,
    },
    computed: {
      linkRel() {
        const { rel, target } = this;
        if ('rel' in this) return rel;
        if (target === '_blank') return 'noreferrer';
        return null;
      }
    }
  }
</script>