import { clear_form_error, initSkills, initSpecializations } from "src/global-helpers.js";

$(function(){
  if(!$('.js-education-course-form').length) return;

  $('#course_city_select input[type="radio"]').on('change', function(){
    if ($(this).closest('.radio_single').hasClass('wo_city')) {
      $(this).closest('#course_city_select').find('.input').addClass('hidden');
      $('#course_city_select #education_course_city_id').prop('disabled', true);
    } else {
      $(this).closest('#course_city_select').find('.input').removeClass('hidden');
      $('#course_city_select #education_course_city_id').prop('disabled', false);
    }
  });

  $('#course_price_select input[type="radio"]').on('change', function(){
    if ($(this).closest('.radio_single').hasClass('wo_price')) {
      $(this).closest('#course_price_select').find('.input').addClass('hidden');
      $('#course_price_select #education_course_price').prop('disabled', true);
      $('#course_price_select #education_course_price_unit').prop('disabled', true);
      $('.fieldset.salary_wrapper').addClass('hidden');
    } else {
      $(this).closest('#course_price_select').find('.input').removeClass('hidden');
      $('#course_price_select #education_course_price').prop('disabled', false);
      $('#course_price_select #education_course_price_unit').prop('disabled', false);
      $('.fieldset.salary_wrapper').removeClass('hidden');
    }
  });

  $('#start_select input[type="radio"]').on('change', function(){
    if (!$(this).closest('.radio_single').hasClass('date')) {
      $(this).closest('#start_select').find('.input.date_age').addClass('hidden');
      $('#start_select #education_course_start_date_3i').prop('disabled', true);
      $('#start_select #education_course_start_date_2i').prop('disabled', true);
      $('#start_select #education_course_start_date_1i').prop('disabled', true);
    } else {
      $(this).closest('#start_select').find('.input.date_age').removeClass('hidden');
      $('#start_select #education_course_start_date_3i').prop('disabled', false);
      $('#start_select #education_course_start_date_2i').prop('disabled', false);
      $('#start_select #education_course_start_date_1i').prop('disabled', false);
    }

    if( $(this).closest('#start_select').find('.input.date_age').parents('.field_error') ) {
      clear_form_error( $(this).closest('#start_select').find('.input.date_age').parents('.field_error') );
    }
  });

  initSkills();

  initSpecializations();

  $('.specialization_name').on('blur', function(){
    if( $(this).val() === '' ) {
      $('input[name="s"]').prop('checked', false);
    }
  });
});
