export const getSsrState = () => {
  const el = document.querySelector('[data-ssr-state]');
  if (el === null) return null;
  return JSON.parse(el.textContent);
};

if (window.location.hostname !== 'career.habr.com') {
  // eslint-disable-next-line
  window._getSsrState = getSsrState;
}
