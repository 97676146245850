import 'src/libs/switchery.min.js';
import 'styles/libs/switchery.min.css';
import {
  company_field,
  initSpecializations,
  setMaxNested,
  skill_field_customize
} from "src/global-helpers.js";

$(function(){

  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-salary-form').length) return;

  company_field($('.company-field'));

  $('.company-field .company_name').on('blur', function(){
    var company_id_input = $('.company-field .company_id');
    if( company_id_input.val() === '' && company_id_input.hasClass('filter') ) {
      $(this).val( '' );
      $(this).trigger('change');
      company_id_input.trigger('change');
    }
  });

  $('.specialization_name').on('blur', function(){
    if( $(this).val() === '' ) {
      $('input[name="s"]').prop('checked', false);
    }
  });

  setMaxNested(3, $('#add_skills'),$('#ihave_skills'));

  $('.skill-field').each(function(index, field){
    skill_field_customize($(field));
  });

  var loadSalariesTm;
  function loadSalariesCount() {
    var data = $('.js-salaries-search-filter-form').serialize();
    $.ajax({
      type: "GET",
      url: '/salaries/count',
      datatype: 'script',
      data: data
    });
  };

  initSpecializations();

  $('.switchery').each(function(index, el){
    var switchery = new Switchery(el);
  });

  $('.js-salaries-search-filter-form').on('change', function(e){
    if (loadSalariesTm) {
      clearTimeout(loadSalariesTm);
    };
    loadSalariesTm = setTimeout(function() { loadSalariesCount(); }, 200);
  });

  $('.js-salaries-search-filter-form .clear_suggest').on('click', function(e){
    if (loadSalariesTm) {
      clearTimeout(loadSalariesTm);
    };
    loadSalariesTm = setTimeout(function() { loadSalariesCount(); }, 200);
  });

  $('.js-salaries-search-filter-form input[name="skill"], .js-salaries-search-filter-form .specialization_name').on('change', function(e){
    if (loadSalariesTm) {
      clearTimeout(loadSalariesTm);
    };
    loadSalariesTm = setTimeout(function() { loadSalariesCount(); }, 200);
  });

  $('.submit_filter').on('click', function(e){
    $('input[name="specialization_name"]').prop('name', '');
    $('input[name="skill"]').prop('name', '');
    $('input[name="location"]').prop('name', '');
    $('input[name="company"]').prop('name', '');
    $('.js-salaries-search-filter-form').submit();
  });

  $('.show_filter_button').on('click', function(e){
    $('.section.vacancies').addClass('hidden');
    $('.section.skills').addClass('hidden');
    $('.section.salaries').find('.body').addClass('hidden');
    $('.section.salaries').find('.filter').removeClass('hidden');
    $('.section.salaries').find('.filter-body').addClass('hidden');
    $('.section.salaries').find('.filter-form').removeClass('hidden');
    if (loadSalariesTm) {
      clearTimeout(loadSalariesTm);
    };
    loadSalariesTm = setTimeout(function() { loadSalariesCount(); }, 200);
  });

  $('.cancel').on('click', function(e){
    $('.section.vacancies').removeClass('hidden');
    $('.section.skills').removeClass('hidden');
    $('.section.salaries').find('.body').removeClass('hidden');
    $('.section.salaries').find('.filter-body').removeClass('hidden');
    $('.section.salaries').find('.filter-form').addClass('hidden');
    $('.section.salaries .title').text("Зарплаты в ИТ");
  });
});
