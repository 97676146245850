$(function(){
  
  // прерываем выполнение скрипта, если нет нужной формы на странице
  if(!$('.js-interest-form').length) return;

  import('src/libs/select2').then(() => {
    // селектор интересов с автокомплитом и возможностью добавления нового
    $('.js-interest-select').select2({
      placeholder: "Новый интерес",
      multiple: true,

      createSearchChoice:function(term, data) {
        if (data.length===0) {
          return {id: term, name: term};
        }
      },

      ajax: {
        url: "/suggest/interests",
        dataType: 'json',
        quietMillis: 250,
        data: function (term, page) {
          return {
            q: term, // search term
          };
        },
        results: function (data, page) {
          return {
            results:  data.interests
          };
        },
        cache: true
      },

      formatResult: formatResult,
      formatSelection: formatSelection,
      escapeMarkup: function (m) { return m; }
    });

    // добавление интереса
    $('.js-interest-select').on("select2-selecting", function(e) {
      e.preventDefault();
      $.post('/profile/interests', { name: e.choice.name });
    });

    // удаление интереса
    $('.js-interests').on('click', '.js-delete', function(){
      var interest = $(this).closest('.js-interest');
      var id = $(this).data('id');
      $.post('/profile/interests/'+id, { '_method': 'delete' });
    });

    // вспомогательная функция которая отвечает за форматирование элемента в списке
    function formatResult(object, container, query){
      object.users_count = object.users_count || 0;
      return object.name + ' <span style="float:right;color:#ccc;">' + object.users_count + ' чел.</span>';
    }

    function formatSelection(object, container, query){
      return object.name;
    }
  });

});