import { clamp } from "src/utils/math.js";

$(function() {
  if ($('.new_message_form').length === 0) {
    return;
  }

  $('.new_message_form textarea').on('change blur focus keyup', function() {
    if ($.trim($(this).val()) !== '') {
      $('.new_message_form button').prop('disabled', false);
    } else {
      $('.new_message_form button').prop('disabled', true);
    }
  });

  $('.new_message_form form').on('submit', function() {
    var text = $.trim($('.new_message_form textarea').val());
    var last_message_id = $(".messages .message").last().data('id') || '';
    if (text !== '') {
      var data = {
        'message[body]': text,
        'last_message_id': last_message_id
      };
      $.post($(this).attr('action'), data, function() {
        $('.new_message_form button').removeClass('loading');
      });
      this.reset();
      $('.new_message_form textarea').focus();
      $('.new_message_form textarea').trigger('autosize');
    }
    return false;
  });

  // по ctrl + enter сабмитим формы :)
  $(document).on('keydown', '.new_message_form textarea', function(e) {
    if ((e.altKey || e.ctrlKey || e.metaKey) && e.keyCode == 13) {
      e.preventDefault();
      $('.new_message_form button').click();
    }
  });

  // авторесайз поля
  $('.new_message_form textarea').autosize({ append: "" });

});

$(function() {
  if ($('.messages .message').length) {
    setTimeout(() => {
      $(".messages").scrollTo($('.messages .message').last(), 0, { axis: 'y' });
    });
  }
});

$(function() {
  if ($('.search_discussions').length === 0) {
    return;
  }

  $('.search_discussions .clear_field').on('click', function() {
    $('#search_discussions_q').val('').trigger('keyup');
    $('.search_discussions .clear_field').addClass('hidden');
  });
  // обработчик сабмита формы в правом сайдбаре
  var timer;
  $('#search_discussions_q').on('keyup', function() {
    $('#search_discussions_q').addClass('loading');
    clearTimeout(timer);
    var q = $(this).val();
    timer = setTimeout(function() {
      if (q.length > 0) {
        $('.search_discussions .clear_field').removeClass('hidden');
      } else {
        $('.search_discussions .clear_field').addClass('hidden');
      }
      $.get('/conversations/search', { term: q }, 'script');
    }, 200);
  });

  $(document).on('click', '.unread.make_read', function(e) {
    var url = $(this).data('url');
    $.ajax({
      url: url,
      method: 'POST',
      dataType: 'script'
    });
    return false;
  });

  $(document).on('click', '.toggle.make_unread', function(e) {
    var url = $(this).data('url');
    $.ajax({
      url: url,
      method: 'POST',
      dataType: 'script'
    });
    return false;
  });
});

$(function() {
  if ($('.messages').length === 0) {
    return;
  }

  const windwHeight = clamp($(window).height(), 400, 1000);
  const messages = $('.conversation_page');
  const discussions = $('.discussions');
  const padding = 20;
  const discussionsHeight = windwHeight - (discussions.offset().top + padding);
  const messagesHeight = windwHeight - (messages.offset().top + padding + 17 /* section padding */);

  discussions.css('height', discussionsHeight + 'px');
  messages.css('height', messagesHeight + 'px');
});