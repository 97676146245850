import 'jquery-ui/ui/widgets/sortable';

$(function(){
  if(!$('.user_graphs').length) return;

  var updateSetsPositions = function(ev){
    var el = $(ev.target);
    console.log(el);
    var ids = [];
    el.find(".set").each(function(i, file){
      ids.push( $(file).data('id') );
    });

    var url = el.data('url');
    $.ajax({
      type: "PATCH",
      url: url,
      data: {ids: ids},
      datatype: 'script'
    });
  };

  $('.sets').each(function(i, el){
    $(el).sortable({
      containment: $(el).closest('.graph'),
      cursor: "move",
      forceHelperSize: true,
      forcePlaceholderSize: true,
      update: updateSetsPositions
    });
  });
});
